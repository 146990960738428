import { MenuAlt1Icon, XCircleIcon } from "@heroicons/react/solid";
import UserDetailForm from "./QuickFirstPage";
import React, { useState, createContext, useEffect, Fragment } from "react";
import { MultiSelect } from "react-multi-select-component";
import Userform from "../../Userform";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getIncident, updateIncident, agreementCreate, getAgreementDocuments, sendEmail, getAllUsers } from "../../../api/user/user.service";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { Menu, Transition } from "@headlessui/react";
import PauseCase from "../../PauseCase";
import EscalateCase from "../../EscalateCase";
import { decodeToken } from "react-jwt";
import { getCookie } from "../../../utils/cookie/cookie";
import { MAIN_URL } from '../../../api/baseurl';

export const UserContext = createContext();
export const CurrentUser = createContext();

const Quickform = () => {

  const infoFun = () => {
    let msg = '';
    console.log(count);
    if (count == 1) {
      msg = `Try to help the participant link emotions to key events.

      This helps them understand different feelings.
      
      Its possible to select/deselect multiple options by simply clicking on
      them.
      
      Please email your questions to us at support@insightbox.com`
    } else if (count == 2) {
      msg = `If you answer 'yes' to the risk of an escalation in conflict, or you
      answer 'no' to the participants willingness to participate in this
      resolution, the Administrator for this service will be notified by
      email, but you can still proceed with the resolution process.
      
      Alternatively, you may also choose to pause or escalate the case
      using the toolkit menu.
      
      Please email your questions to us at support@insightbox.com`
    } else if (count == 3) {
      msg = `Voice each solution clearly to the participants and ask if they are
      both in agreement.
      
      Select the solution to populate the Agreement.
      
      Move on if participants do not agree with a particular solution.
      
      Additional solutions can be added using the 'Other' box if required.
      
      Try to ensure that all participants get a chance to speak.
      
      Don't labour points.
      
      Keep the discussion flowing.
      
      Try to find some common ground. If the stated solutions are not
      accepted - suggest alternatives.
      
      At this point in the process its about the participants feeling that
      they have been heard and finding that there is a way forward.
      
      Please email your questions to us at support@insightbox.com`
    } else if (count == 4) {
      msg = `Its important the participants sign the Agreement.

      Make sure all participants have a copy of the Agreement, as a
      reminder of their promises.
      
      Please email your questions to us at support@insightbox.com`
    } else if (count == 5) {
      msg = `Approximately 7 - 10 days after the agreement has been reached,
      check-in with the participants to answer whether or not they are
      sticking to the agreed requests in the contract. If they feel that the
      resolution is satisfactory and they feel safe. Then you can give
      them the feedback forms for completion.
      
      When the participants have responded, the data can be captured
      here by selecting the feedback response?`
    }
    if (msg.length > 0) {
      Swal.fire({
        text: msg,
        showConfirmButton: false,
      })
    }
  }

  var navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { _id } = useParams();
  const ref = React.createRef();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  const intialMainFormData = Object.freeze({ _id: '', description: '', users: [], referralsMade: [], agenciesSupporting: [], particioantsEscalate: '', willingToResolve: '', classification: [], externalProfessional: '', successResolution: '', balanceRepresentation: '', speakingOrder: [], agreegingSolution: [], feedbackStatus: '' })
  const [mainFormData, updateMainFormData] = React.useState(intialMainFormData);

  const [users, setUsers] = useState(mainFormData.users);
  const [count, setCount] = useState(1);

  const [adminUsers, setAdminUsers] = useState([]);
  const [userFormFor4th, setUserFormFor4th] = useState(false);

  const [agreementPDFs, setAgreementPDFs] = useState([])


  const popupUserForm4 = () => setUserFormFor4th(true);
  const popupUserForm4Close = () => setUserFormFor4th(false);

  const [pauseCaseDiv, setPauseCaseDiv] = useState(false);

  const popupPauseCaseDiv = () => setPauseCaseDiv(true);
  const popupPauseCaseDivClose = () => setPauseCaseDiv(false);

  const [escalateCaseDiv, setEscalateCaseDiv] = useState(false);

  const popupEscalateCaseDiv = () => setEscalateCaseDiv(true);
  const popupEscalateCaseDivClose = () => setEscalateCaseDiv(false);



  const handleChangeMainForm = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    let objForEmail = {
      administrator: getAdminUserName(mainFormData.admin),
      case_id: mainFormData._id,
      practitioner_name: getAdminUserName(mainFormData.user),
      incident_date: mainFormData.createdAt,
      description: mainFormData.description,
      email: getAdminUserEmail(mainFormData.admin)
    }
    if (e.target.name == 'particioantsEscalate' && e.target.value == 'Yes') {
      sendEmail('email-b', objForEmail).then((response) => {
        console.log(response);
      });
    } else if (e.target.name == 'willingToResolve' && e.target.value == 'No') {
      sendEmail('email-c', objForEmail).then((response) => {
        console.log(response);
      });
    }
    updateMainFormData({
      ...mainFormData,
      [e.target.name]: e.target.value
    });
  };

  const [steps, setSteps] = useState([
    { id: "1", page: '1', name: `One to one`, href: "#", status: "current" },
    { id: "2", page: '3', name: "Possible Solution", href: "#", status: "upcoming" },
    { id: "3", page: '4', name: "Agreements", href: "#", status: "upcoming" },
    { id: "4", page: '5', name: "Feedback", href: "#", status: "upcoming" },
  ]);

  const prevStep = () => {
    setCount(count - 1);
    let currentSteps = steps;
    let flag = false;
    for (let i = 0; i < currentSteps.length; i++) {
      if (currentSteps[i].page >= count && flag == false) {
        currentSteps[i].status = 'current';
        flag = true;
      }
    }
    setSteps(currentSteps);
  };

  const nextStep = (data) => {
    updateIncident(data)
      .then((response) => {
        if (count == 1) {
          updateMainFormData({ ...response.data })
        }
        console.log(response);
      });
    console.log("count :: " + count);
    if (count < 5) {
      if (count == 1) {
        console.log("count 1");

        setCount(count + 1);
      }
      else if (count == 2) {
        console.log("count 2");
        setCount(count + 1);
        let currentSteps = steps.map((currentStep, index) => ({
          ...currentStep,
          status: index === 1 ? "current" : currentStep.status,
        }));
        currentSteps = currentSteps.map((currentStep, index) => ({
          ...currentStep,
          status: index === 0 ? "complete" : currentStep.status,
        }));
        setSteps(currentSteps);
      }
      else if (count == 3) {
        console.log("count 3");

        // setUserSummery(true)
        setCount(count + 1);
        let currentSteps = steps.map((currentStep, index) => ({
          ...currentStep,
          status: index === 2 ? "current" : currentStep.status,
        }));
        currentSteps = currentSteps.map((currentStep, index) => ({
          ...currentStep,
          status: index === 1 ? "complete" : currentStep.status,
        }));
        setSteps(currentSteps);
      }
      else if (count == 4) {
        setCount(count + 1);
        let currentSteps = steps.map((currentStep, index) => ({
          ...currentStep,
          status: index === 3 ? "current" : currentStep.status,
        }));
        currentSteps = currentSteps.map((currentStep, index) => ({
          ...currentStep,
          status: index === 2 ? "complete" : currentStep.status,
        }));
        setSteps(currentSteps);
      } else {
        setCount(count + 1);
        let currentSteps = steps.map((currentStep, index) => ({
          ...currentStep,
          status: index === count ? "current" : currentStep.status,
        }));
        currentSteps = currentSteps.map((currentStep, index) => ({
          ...currentStep,
          status: index === (count - 1) ? "complete" : currentStep.status,
        }));
        setSteps(currentSteps);
      }
    } else if (count >= 5) {
      let currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        status: index === 4 ? "complete" : currentStep.status,
      }));
      setSteps(currentSteps);
      Swal.fire({
        icon: 'success',
        title: 'Form data has been saved',
        showConfirmButton: false,
        timer: 1000
      })
      navigate("/my_incidents");
    }
  };

  const HomeFun = () => {
    navigate("/my_incidents");
  }



  const Continue = (data) => {
    console.log(mainFormData)
    if (count == 1) {
      if (users.length > 1) {
        for (let i = 0; i < users.length; i++) {
          if (users[i].status == '' && users[i].addedBy == '') {
            return Swal.fire({
              title: 'Please select the Participant to take them through the one-to-one process.',

            })
            break;
          }
        }
        nextStep(mainFormData);
      } else {
        return Swal.fire({
          text: 'Please add minimum 2 Participants before continuing',
        })
      }

    }
    else if (count === 2) {
      updateMainFormData({
        ...data,
        agenciesSupporting: selectedOptionSupporting,
        referralsMade: selectedOptionReferrals,
      })
      nextStep({
        ...data,
        agenciesSupporting: selectedOptionSupporting,
        referralsMade: selectedOptionReferrals,
      });
    }
    else if (count == 3) {
      Swal.fire({
        title: 'Do you need anything else from anyone?',
        showCancelButton: true,
        confirmButtonColor: 'white',
        cancelButtonColor: 'black',
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
      }).then((result) => {
        if (!result.isConfirmed) {
          updateMainFormData({
            ...data,
            agreegingSolution: [...rowsDataSolution]
          });

          // agreementCreate(rowsDataSolution)
          //   .then((response) => {
          //     console.log(response);
          //   });
          let objForPDF = JSON.parse(JSON.stringify(rowsDataSolution))
          let temp;
          for (let i = 0; i < objForPDF.length; i++) {
            console.log("objForPDF[i].name");
            console.log(objForPDF[i].name);
            for (let j = 0; j < users.length; j++) {
              if (users[j].id == objForPDF[i].name) {
                objForPDF[i].name = users[j].firstName + ' ' + users[j].lastName;
              }
              if (users[j].id == objForPDF[i].name2) {
                objForPDF[i].name2 = users[j].firstName + ' ' + users[j].lastName;
              };
            }
          }
          const authorization = getCookie('authorization')
          const decodedToken = decodeToken(authorization);
          let objectForAgreementPDF = {
            case_id: mainFormData._id,
            // facilitator: mainFormData.admin,
            practitioner: mainFormData.user,
            // printedBy: decodedToken.email,
            createdAt: mainFormData.createdAt,
            solutions: JSON.parse(JSON.stringify(objForPDF))
          }


          setIsLoading(true)
          agreementCreate(objectForAgreementPDF)
            .then((response) => {
              console.log("agreement Created");
              console.log(response);
            }).finally(() => {
              setIsLoading(false)
              nextStep({
                ...data,
                agreegingSolution: [...rowsDataSolution]
              });
              getAgreementDocuments(mainFormData._id).then((res) => {
                setAgreementPDFs([...res.data])
              })
            })
        }
      })
    } else if (count == 4) {
      Swal.fire({
        html: "Remind the participants to contact you if any promises are broken.<br> It's good practice to check back in with the participants in around 10 days to make sure that they are keeping their promises. ",
        // showCancelButton: true,
        confirmButtonColor: '#000000',
        confirmButtonText: 'OK',
        // showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          updateMainFormData({
            ...data,
            classification: [...rowsDataClassification]
          });
          nextStep({
            ...data,
            classification: [...rowsDataClassification]
          });

        }
      })
    }
    else {
      nextStep();
    }
    console.log(mainFormData)
  };



  const handleStep = (e, step) => {
    step = Number(step)
    setCount(step);
    console.log(step)
    if (step == 5) {
      getAgreementDocuments(mainFormData._id).then((res) => {
        setAgreementPDFs([...res.data])
      })
    }
    let currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      status: currentStep.page == step ? currentStep.status === 'complete' ? "complete" : "current" : currentStep.status
    }));
    currentSteps = currentSteps.map((currentStep, index) => ({
      ...currentStep,
      status: currentStep.page != step ? currentStep.status != 'complete' ? "upcoming" : currentStep.status : currentStep.status
    }));
    setSteps(currentSteps);
  }

  // for step 2 multi options
  const [selectedOptionReferrals, setSelectedOptionReferrals] = useState(mainFormData.referralsMade);
  const [selectedOptionSupporting, setSelectedOptionSupporting] = useState(mainFormData.agenciesSupporting);
  // const [userForm, setUserForm] = useState(false);setSelectedOptionSupporting


  // setRows(userNameList)
  const classificationMenu = [{ value: "Person causing harm" }, { value: "Person being harmed" }, { value: "Supporter of person causing harm" }, { value: "Supporter of person being harmed" }, { value: "Bystander" }, { value: "Orchestrator" }, { value: "Professional" }, { value: "Participant" }];

  const optionsforReferrals = [
    { value: "Social Services", label: "Social Services" },
    { value: "CHAMS", label: "CHAMS" },
    { value: "Early Help", label: "Early Help" },
    { value: "School Nurse", label: "School Nurse" },
    { value: "Police", label: "Police" },
    { value: "Therapist", label: "Therapist" },
    { value: "Mentor", label: "Mentor" },
    { value: "Other", label: "Other" },

  ];

  const optionsforOtherAgenciedSupporting = [
    { value: "Social Services", label: "Social Services" },
    { value: "CHAMS", label: "CHAMS" },
    { value: "Early Help", label: "Early Help" },
    { value: "School Nurse", label: "School Nurse" },
    { value: "Police", label: "Police" },
    { value: "Therapist", label: "Therapist" },
    { value: "Mentor", label: "Mentor" },
    { value: "Other", label: "Other" },
  ];

  const optionsforSolutions = [
    "To be friends",
    "To make amends",
    "To not communicate with each other",
    "To say sorry",
    "Not get involved when other pupils are being hurtful",
    "To tell our carer if something happens",
    "To be polite to each other",
    "To listen to what they have to say",
    "To tell our parent if something happens",
    "To make sure everyone can be involved",
    "To let our friends know we have moved on",
    "To go to a teacher first",
    "To keep an open mind",
    "To stop saying upsetting things",
    "To contribute to the solution",
    "To make sure everyone feels safe",
    "To stay away from each other",
    "To ignore comments from other pupils",
    "To hear why they did it",
    "To be respectful",
    "Other ADD",
  ];

  // handle subform submit
  const [subForm, setSubForm] = useState(false);
  const [currentUserForSubForm, setCurrentUserForSubForm] = useState(null);
  const popupSubForm = (e) => {
    if (users.length > 1) {
      setCurrentUserForSubForm(e);
      setSubForm(true);
    } else {
      Swal.fire({
        text: 'Please add minimum 2 users',
      })
    }
  }

  // get all admin users

  const getAllAdminUsers = () => {
    getAllUsers()
      .then((response) => {
        console.log(response);
        setAdminUsers(response.data);
        setIsLoading(false)
      }).catch((error) => {
        console.log(error.response.status)
        if (error.response.status == 401) {

        }
        return error.response
      });
  }

  // get user name 

  const getAdminUserName = (id) => {
    console.log(adminUsers);
    for (let i = 0; i < adminUsers.length; i++) {
      console.log(adminUsers[i]._id);
      console.log(id);
      if (adminUsers[i]._id == id) {
        return `${adminUsers[i].first_name} ${adminUsers[i].last_name}`
      }
    }
  }

  // get user email

  const getAdminUserEmail = (id) => {
    for (let i = 0; i < adminUsers.length; i++) {
      if (adminUsers[i]._id == id) {
        return adminUsers[i].email
      }
    }
  }

  // pause case

  const pauseCase = (e) => {
    updateMainFormData({
      ...mainFormData,
      formStatus: 'pause',
      formStatusReason: e
    })
    updateIncident({
      ...mainFormData,
      formStatus: 'pause',
      formStatusReason: e
    })
      .then((response) => {
        console.log(response);
      });
    popupPauseCaseDivClose();
    navigate("/my_incidents");
    let objForEmail = {
      administrator: getAdminUserName(mainFormData.admin),
      case_id: mainFormData._id,
      practitioner_name: getAdminUserName(mainFormData.user),
      incident_date: mainFormData.createdAt,
      description: mainFormData.description,
      reason: e.reason,
      email: getAdminUserEmail(mainFormData.admin)
    }
    console.log(objForEmail);
    sendEmail('email-f', objForEmail).then((response) => {
      console.log(response);
    });
  }

  const triageCase = () => {
    updateMainFormData({
      ...mainFormData,
      formStatus: 'triage'
    })
    updateIncident({
      ...mainFormData,
      formStatus: 'triage'
    })
      .then((response) => {
        console.log(response);
      });

    popupSubFormClose();
    navigate("/my_incidents");
    let objForEmail = {
      administrator: getAdminUserName(mainFormData.admin),
      case_id: mainFormData._id,
      practitioner_name: getAdminUserName(mainFormData.user),
      incident_date: mainFormData.createdAt,
      description: mainFormData.description,
      email: getAdminUserEmail(mainFormData.admin)
    }
    console.log(objForEmail);
    sendEmail('email-a', objForEmail).then((response) => {
      console.log(response);
    });
  }

  const escalatedCase = (e) => {
    updateMainFormData({
      ...mainFormData,
      formStatus: 'escalated',
      user: e.escalateTo,
      formStatusReason: e
    })
    updateIncident({
      ...mainFormData,
      formStatus: 'escalated',
      formStatusReason: e,
      user: e.escalateTo
    })
      .then((response) => {
        console.log(response);
      });
    popupEscalateCaseDivClose();
    navigate("/my_incidents");
    let objForEmail = {
      administrator: getAdminUserName(mainFormData.admin),
      case_id: mainFormData._id,
      practitioner_name: getAdminUserName(mainFormData.user),
      incident_date: mainFormData.createdAt,
      description: mainFormData.description,
      reason: e.reason,
      q1: e.forSafety,
      q2: e.noParticipants,
      q3: e.assistProcess,
      email: getAdminUserEmail(mainFormData.admin)
    }
    console.log(objForEmail);
    sendEmail('email-g', objForEmail).then((response) => {
      console.log(response);
    });
  }

  // close case 

  const closeCaseBtn = () => {

    let objForEmail = {
      case_id: mainFormData._id,
      practitioner_name: getAdminUserName(mainFormData.user),
      email: getAdminUserEmail(mainFormData.admin)
    }
    sendEmail('email-l', objForEmail).then((response) => {
      console.log(response);
    });
    updateMainFormData({
      ...mainFormData,
      formStatus: 'closed'
    })
    updateIncident({
      ...mainFormData,
      formStatus: 'closed'
    })
      .then((response) => {
        console.log(response);
      });
    popupEscalateCaseDivClose();
    navigate("/my_incidents");
  }

  const popupSubFormClose = () => {
    setCurrentUserForSubForm(null);
    setSubForm(false);
  }

  const getSubFormData = (json) => {

    const newUsersData = users.map((user) => ({
      ...user,
      data: user.id === currentUserForSubForm ? json : user.data,
      status: user.id === currentUserForSubForm ? "complete" : user.status,
    }));
    console.log("newUsersData after sub form submit")
    console.log(newUsersData)
    setUsers(newUsersData)
    updateMainFormData({
      ...mainFormData,
      users: [...newUsersData]
    })
    updateIncident({
      ...mainFormData,
      users: [...newUsersData]
    })
      .then((response) => {
        console.log(response);
      });


    setSubForm(false);
  }


  // handle form submit for USerForm 
  const [userForm, setUserForm] = useState(false);
  const popupUserForm = () => {
    setUserForm(true);
  }

  const popupUserFormClose = () => setUserForm(false);

  const getUserFormData = (data) => {

    data.id = (users.length + 1);
    data.addedBy = '';
    setUsers(oldArray => [...oldArray, data]);
    updateMainFormData({
      ...mainFormData,
      users: [...users, data]
    })
    updateIncident({
      ...mainFormData,
      users: [...users, data]
    })
      .then((response) => {
        console.log(response);
      });
    setUserForm(false);
  }

  // add addedBy in user 
  const getUserFormDataFromUser = (data) => {
    data.id = (users.length + 1);
    console.log(currentUserForSubForm)
    data.addedBy = users[currentUserForSubForm - 1].id;
    setUsers(oldArray => [...oldArray, data]);
    updateMainFormData({
      ...mainFormData,
      users: [...users, data]
      // setRowsData([...rowsData, rowsDataTemp])
    })
    updateIncident({
      ...mainFormData,
      users: [...users, data]
    })
      .then((response) => {
        console.log(response);
      });

    setUserForm(false);
  }

  // for table rows for speaking order
  const [rowsData, setRowsData] = useState(mainFormData.speakingOrder);
  const intialRowData = Object.freeze({ name: '', position: '', present: false })
  const [rowsDataTemp, setRowsDataTemp] = React.useState(intialRowData);

  const handleChangeDataforTable = (e) => {
    setRowsDataTemp({
      ...rowsDataTemp,
      [e.target.name]: e.target.value
    });
  }

  const addTableRows = () => {
    if (rowsDataTemp.name) {
      setRowsData([...rowsData, rowsDataTemp])
      setRowsDataTemp(intialRowData)
    }
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }

  const handleChangeforTable = (index, e) => {

    const { name, checked } = e.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = checked;

    for (let i = 0; i < rowsInput.length; i++) {
      rowsInput[i]['position'] = (i + 1)
    }

    setRowsData(rowsInput);
  }

  // for Agreeing solution table

  // for custom other checkbox value for solutions
  const [otherCheckBox1, setOtherCheckBox1] = useState('')
  const [otherCheckBox2, setOtherCheckBox2] = useState('')

  const [rowsDataSolution, setRowsDataSolution] = useState(mainFormData.agreegingSolution);
  const intialRowDataSolution = Object.freeze({ name: '', name2: '', solutions: [] })
  const [rowsDataSolutionTemp, setRowsDataSolutionTemp] = React.useState(intialRowDataSolution);

  // temporery array for selected solution in one to one
  const [tempArrayForSelectedSolutions, setTempArrayForSelectedSolutions] = useState([])

  const handleChangeSolutionDataforTable = (e) => {
    const res = e.target;
    let temp1 = []
    let temp2 = []
    console.log(mainFormData)
    if (res.name == 'name2' && res.value != '' && rowsDataSolutionTemp.name != '') {
      console.log(rowsDataSolutionTemp.name)
      console.log(res.value)
      mainFormData.users.map((user, key) => {
        if (user.id == rowsDataSolutionTemp.name) {
          console.log(user.data.solutions)
          user.data.solutions.map((solution) => {
            if (solution.name === res.value) {
              temp1 = [solution.solutions];
            }
          })
        }
        else if (user.id == res.value) {
          console.log(res.value)
          user.data.solutions.map((solution) => {
            if (solution.name == rowsDataSolutionTemp.name) {
              temp2 = [solution.solutions]
            }
          })
        }
      })
    }
    if (res.name == 'name' && res.value != '' && rowsDataSolutionTemp.name2 != '') {
      mainFormData.users.map((user, key) => {
        if (user.id == rowsDataSolutionTemp.name2) {
          user.data.solutions.map((solution) => {
            if (solution.name == res.value) {
              temp1 = [solution.solutions]
            }
          })
        } else if (user.id == res.value) {
          user.data.solutions.map((solution) => {
            if (solution.name == rowsDataSolutionTemp.name2) {
              temp2 = [solution.solutions]
            }
          })
        }
      })
    }
    console.log(temp1)
    console.log(temp2)
    if (temp1.length > 0 && temp2.length > 0) {
      let temp3 = [...temp1[0], ...temp2[0]]
      let uniqueChars = [...new Set(temp3)];
      console.log(uniqueChars)
      if (uniqueChars.length > 0) {
        console.log(uniqueChars)
        setTempArrayForSelectedSolutions(uniqueChars)
      }
      else {
        console.log(optionsforSolutions)
        setTempArrayForSelectedSolutions(optionsforSolutions)
      }
      console.log(uniqueChars)
    } else {
      console.log(optionsforSolutions)
      setTempArrayForSelectedSolutions(optionsforSolutions)
    }
    setRowsDataSolutionTemp({
      ...rowsDataSolutionTemp,
      [e.target.name]: e.target.value
    });
  }

  const handleChangeForCheckboxs = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { solutions } = rowsDataSolutionTemp;

    if (checked) {
      setRowsDataSolutionTemp({
        ...rowsDataSolutionTemp,
        solutions: [...solutions, value],
      });
    }
    else {
      setRowsDataSolutionTemp({
        ...rowsDataSolutionTemp,
        solutions: solutions.filter((e) => e !== value),
      });
    }
  };

  const addSolutionTableRows = (e) => {
    e.preventDefault();
    if (rowsDataSolutionTemp.name != '' && rowsDataSolutionTemp.name2 != '') {
      if (rowsDataSolutionTemp.name == rowsDataSolutionTemp.name2) {
        Swal.fire({
          text: 'Please select different participants before continuing',
        })
      }
      else if (rowsDataSolutionTemp.solutions.length >= 2) {
        let flag = false
        rowsDataSolution.map((e) => {
          if (e.name == rowsDataSolutionTemp.name) {
            if (e.name2 == rowsDataSolutionTemp.name2) {
              flag = true;
            }
          }
          else if (e.name == rowsDataSolutionTemp.name2) {
            if (e.name2 == rowsDataSolutionTemp.name) {
              flag = true;
            }
          }
        })
        if (flag) {

          setRowsDataSolutionTemp(intialRowDataSolution)
          document.querySelectorAll('input[name=agreeingSolutionCheckbox]').forEach(el => el.checked = false);
          document.getElementById('mainAgreementName').selectedIndex = 0;
          document.getElementById('mainAgreementName2').selectedIndex = 0;
          setTempArrayForSelectedSolutions(optionsforSolutions)
          return Swal.fire({
            text: 'These participants have an existing agreement. To add/edit solutions, first delete the current agreement. (select the ‘X’ below). Then create a new agreement for the pair.'
          })
        } else {
          setRowsDataSolution(rowsDataSolution => [...rowsDataSolution, rowsDataSolutionTemp]);
          setRowsDataSolutionTemp(intialRowDataSolution)
          setTempArrayForSelectedSolutions(optionsforSolutions)
          document.getElementById('mainAgreementName').selectedIndex = 0;
          document.getElementById('mainAgreementName2').selectedIndex = 0;
          document.querySelectorAll('input[name=agreeingSolutionCheckbox]').forEach(el => el.checked = false);
        }

      } else {
        Swal.fire({
          text: 'Please select minimum 2 solutions before continuing',
        })
      }
    } else {
      Swal.fire({
        text: 'Please select the person from the dropdown before continuing',
      })
    }
  }

  const deleteSolutionTableRows = (index) => {
    const rows = [...rowsDataSolution];
    rows.splice(index, 1);
    setRowsDataSolution(rows);
  }



  // for Classification and risk table

  const [rowsDataClassification, setRowsDataClassification] = useState(mainFormData.classification);
  const intialRowDataClassification = Object.freeze({ name: '', classification: '', jointMeeting: '', neededForResolution: '', helpfulToResolution: '', safeToMeet: '', willingToMeet: '' })
  const [rowsDataClassificationTemp, setRowsDataClassificationTemp] = React.useState(intialRowDataClassification);

  const handleChangeClassificationDataforTable = (e) => {
    let objForEmail = {
      administrator: getAdminUserName(mainFormData.admin),
      case_id: mainFormData._id,
      practitioner_name: getAdminUserName(mainFormData.user),
      incident_date: mainFormData.createdAt,
      description: mainFormData.description,
      email: getAdminUserEmail(mainFormData.admin)
    }
    if (e.target.name == 'willingToMeet' && e.target.value == 'No') {
      sendEmail('email-d', objForEmail).then((response) => {
        console.log(response);
      });
    } else if (e.target.name == 'safeToMeet' && e.target.value == 'No') {
      sendEmail('email-e', objForEmail).then((response) => {
        console.log(response);
      });
    } else if (e.target.name == 'jointMeeting' && e.target.value == 'No') {
      sendEmail('email-e', objForEmail).then((response) => {
        console.log(response);
      });
    }
    setRowsDataClassificationTemp({
      ...rowsDataClassificationTemp,
      [e.target.name]: e.target.value
    });
  }

  const addClassificationTableRows = (e) => {
    e.preventDefault();
    if (rowsDataClassificationTemp.name != '') {
      setRowsDataClassification(rowsDataClassification => [...rowsDataClassification, rowsDataClassificationTemp]);
      setRowsDataClassificationTemp(intialRowDataClassification)
    }
    else {
      Swal.fire({
        text: 'Please select the Person and their Classification from the dropdown above',
      })
    }
  }

  const deleteClassificationTableRows = (index) => {
    const rows = [...rowsDataClassification];
    rows.splice(index, 1);
    setRowsDataClassification(rows);
  }

  //  send feedbackForm to participants

  const sendParticipantFeedback = () => {
    setIsLoading(true);


    updateMainFormData({
      ...mainFormData,
      feedbackStatus: 'sent'
    });
    let objForEmail = [];
    for (let i = 0; i < mainFormData.users.length; i++) {
      if (mainFormData.users[i].email) {
        objForEmail.push({
          participant_name: mainFormData.users[i].firstName + " " + mainFormData.users[i].lastName,
          link: MAIN_URL + "/feedback/" + mainFormData._id + '/' + mainFormData.users[i]._id,
          email: mainFormData.users[i].email
        })

        console.log(objForEmail);

      }

    }
    let body = { data: objForEmail }
    sendEmail('email-k', body).then((response) => {
      console.log(response);
    });

    updateIncident({
      ...mainFormData,
      feedbackStatus: 'sent'
    })
      .then((response) => {
        console.log(response);
        Swal.fire({
          text: 'Feedback forms have been sent to all participants'
        })
      }).finally(() => {
        setIsLoading(false)
      });

  }

  useEffect(() => {
    setIsLoading(true)
    getAllAdminUsers();
    getIncident(_id)
      .then((response) => {
        setUsers(response.data.users)
        setSelectedOptionReferrals(response.data.referralsMade)
        setSelectedOptionSupporting(response.data.agenciesSupporting)
        setRowsDataClassification(response.data.classification)
        setRowsData(response.data.speakingOrder)
        setRowsDataSolution(response.data.agreegingSolution)
        updateMainFormData(response.data)
        setIsLoading(false)
        console.log(response.data)
      });
  }, []);

  return (
    <div>
      {isLoading ? <Loader /> : ""}
      <UserContext.Provider value={users}>
        <CurrentUser.Provider value={currentUserForSubForm}>


          <nav className="w-full mx-auto my-8 3xl:my-14 ">
            <ol role="list" className="flex items-center ml-[15%] md:ml-[22%] lg:ml-[30%] md:mr-[8%] ">
              {steps.map((step, stepIdx) => (
                <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-[8%] md:pr-[13%] ' : '', 'relative')}>
                  {step.status === 'complete' ? (
                    <>
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="h-0.5 w-full bg-green-600" />
                      </div>
                      <a
                        onClick={event => handleStep(event, step.page)}
                        className="relative cursor-pointer w-10 h-10 3xl:w-14 3xl:h-14  flex items-center justify-center bg-green-800 rounded-full hover:bg-green-900"
                      >
                        <span className="font-bold text-white 3xl:text-[1.5rem]">{step.id}</span>
                        <p className="-m-px p-0 absolute top-10 md:block my-2 3xl:top-14 3xl:text-[1.5rem] 3xl:w-48 hidden font-bold text-gray-800 w-32 text-center">{step.name}</p>

                      </a>
                    </>
                  ) : step.status === 'current' ? (
                    <>
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="h-0.5 w-full bg-gray-600" />
                      </div>
                      <a
                        onClick={event => handleStep(event, step.page)}
                        className="relative cursor-pointer w-10 h-10 3xl:w-14 3xl:h-14 flex items-center justify-center bg-white border-[3px] 3xl:border-4 border-green-700 rounded-full"
                        aria-current="step"
                      >
                        <span className="font-bold text-green-600 3xl:text-[1.5rem]" >{step.id}</span>
                        <p className="-m-px p-0 absolute top-10 md:block my-2 3xl:top-14 3xl:text-[1.5rem] 3xl:w-48  font-bold hidden text-green-600 w-28 text-center">{step.name}</p>
                      </a>
                    </>
                  ) : (
                    <>
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="h-0.5 w-full bg-gray-600" />
                      </div>
                      <a
                        onClick={event => handleStep(event, step.page)}
                        className="group cursor-pointer relative w-10 h-10 3xl:w-14 3xl:h-14 flex items-center justify-center bg-white border-[3px] 3xl:border-4 border-gray-600 rounded-full hover:border-gray-800"
                      >
                        <span className="font-semibold text-gray-600 3xl:text-[1.5rem]">{step.id}</span>
                        <p className="-m-px p-0 absolute font-semibold top-10 md:block 3xl:top-14 3xl:text-[1.5rem] 3xl:w-48 my-2 hidden text-gray-500 w-28 text-center">{step.name}</p>

                      </a>
                    </>
                  )}
                </li>
              ))}
            </ol>
          </nav>
          <div
            className="flex mt-6 md:mt-14 lg:mt-16 xl:mt-20 3xl:mt-28 justify-center items-center mx-2 md:mx-4"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div
              className="flex-1 md:max-w-6xl xl:max-w-[80rem] 3xl:max-w-[80%] bg-white min-h-full mx-auto p-2 md:p-4 shadow-md rounded-lg"
              style={{ backgroundColor: "#F8F8F8" }}
            >

              <div id="mainCon" className="p-3 md:p-4 xl:p-12 3xl:p-[5%] rounded mt-2 md:mt-3">
                <div className="font-medium justify-end items-end flex text-gray-900">
                  <div className="md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xsrounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <MenuAlt1Icon
                              className="w-6 h-6"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item key="Info">
                              {({ active }) => (
                                <button
                                  onClick={infoFun}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm w-full text-gray-700"
                                  )}
                                >
                                  Info
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item key="Pause">
                              {({ active }) => (
                                <button
                                  onClick={popupPauseCaseDiv}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm w-full text-gray-700"
                                  )}
                                >
                                  Pause
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item key="Escalate">
                              {({ active }) => (
                                <button
                                  onClick={popupEscalateCaseDiv}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm w-full text-gray-700"
                                  )}
                                >
                                  Escalate
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item key="Home">
                              {({ active }) => (
                                <button
                                  onClick={HomeFun}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm w-full text-gray-700"
                                  )}
                                >
                                  Home
                                </button>
                              )}
                            </Menu.Item>
                            {/* ))} */}
                          </Menu.Items>
                        </Transition>
                      </Menu>

                    </div>
                  </div>
                  {/* <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button> */}
                </div>
                {pauseCaseDiv ? (
                  <>
                    <div
                      id="overlay"
                      className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                    />
                    <div
                      id="dialogForPerson"
                      className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                    >
                      <PauseCase onSubmit={pauseCase} popupClose={popupPauseCaseDivClose} />
                    </div>
                  </>
                ) : (
                  ""
                )}
                {escalateCaseDiv ? (
                  <>
                    <div
                      id="overlay"
                      className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                    />
                    <div
                      id="dialogForPerson"
                      className=" flex-1 min-h-screen fixed inset-0 flex items-center w-fit my-2 md:max-w-[70%] mx-auto z-50 bg-white rounded-md space-y-5 drop-shadow-lg"
                    >
                      <EscalateCase onSubmit={escalatedCase} popupClose={popupEscalateCaseDivClose} />
                    </div>
                  </>
                ) : (
                  ""
                )}
                {count == 1 ? (
                  // <form
                  //   onSubmit={popupUserForm}
                  //   id="section1"
                  //   className="formComp mb-4"
                  // >
                  <>
                    <div className="justify-center mt-3 mb-4 md:mb-7">
                      <p className="text-2xl md:text-3xl 3xl:text-4xl font-bold text-center mainTitle">
                        Who's involved
                      </p>
                      {/* <p className="text-2xl md:text-3xl xl:text-4xl 3xl:text-5xl font-bold text-center  mainTitle">
                        Who's involved
                      </p> */}
                      <div className="border-b-2 col-span-full my-3 md:my-6" />

                    </div>
                    <p className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600">
                      Add the people involved in the incident
                    </p>
                    <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                    <div className="flex">
                      <button
                        onClick={popupUserForm}
                        type="button"
                        id="addPerson"
                        className="inline-flex text-[1.1rem] font-semibold rounded-md text-gray-600 max-h-12 bottom-0 my-auto items-center px-6 py-2 border-2 border-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                      >
                        <span>Add person +</span>
                      </button>
                    </div>
                    <div
                      id="personDiv"
                      className="py-8 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5"
                    >
                      {users.map((e) => {
                        return (
                          <div className="rounded overflow-hidden shadow-lg">
                            <button type="button" id={e.id} onClick={() => { setCurrentUserForSubForm(e.id); popupSubForm(e.id); }} className={e.status == '' ? "px-4 py-2 items-center flex flex-col h-full w-full hover:bg-gray-200 text-[green] border-2 border-gray-500" : 'px-4 py-2 items-center flex flex-col h-full bg-green-600 hover:bg-green-800 w-full'}>
                              <span className="font-bold text-xl text-center" id="name_${cards}">{e.firstName + " " + e.lastName}</span>
                            </button>
                          </div>
                        );
                      })}

                    </div>

                    <p className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600">
                      Select the participants above to continue with the resolution service
                    </p>

                    {userForm ? (
                      <>
                        <div
                          id="overlay"
                          className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                        />
                        <div
                          id="dialogForPerson"
                          className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                        >
                          <Userform onSubmit={getUserFormData} popupClose={popupUserFormClose} />

                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {subForm ? (
                      <>
                        <div
                          id="overlay"
                          className="fixed z-40 w-screen h-full inset-0 bg-gray-900 bg-opacity-60"
                        />
                        <div
                          id="dialogForPerson"
                          className="overflow-x-hidden overflow-y-auto min-w-[75%] md:w-[75%] h-[95%] min-h-[75%] flex-1 mx-auto fixed inset-x-px top-4 z-50 bg-white rounded-md space-y-5 drop-shadow-lg"
                        >
                          <UserDetailForm onSubmit={getSubFormData} onSubFormClose={popupSubFormClose} onPauseCase={pauseCase} onTriageCase={triageCase} onEscalatedCase={escalatedCase} onUserFormSubmit={getUserFormDataFromUser} />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                  </>
                ) : (
                  ""
                )}
                {count == 2 ? (
                  <div>
                    <form
                      onSubmit={popupUserForm}
                      id="section2"
                      className="formComp mb-4"
                    >
                      <div className="justify-center mt-3 mb-4 md:mb-7">
                        <p className="text-2xl md:text-3xl xl:text-4xl 3xl:text-5xl font-bold text-center  mainTitle">
                          Practitioner to answer
                        </p>
                        <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                      </div>
                      <div className="grid grid-cols-12 gap-3 md:gap-8 p-2 md:p-4">
                        <div className="md:col-span-6 col-span-full">
                          <label
                            htmlFor="description"
                            className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                          >
                            Have referrals been made? <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="mt-2 relative rounded-md shadow-sm">
                            <MultiSelect
                              options={optionsforReferrals}
                              value={selectedOptionReferrals}
                              onChange={setSelectedOptionReferrals}
                              labelledBy="Select"
                              isCreatable={true}
                              hasSelectAll={false}
                            />
                          </div>
                        </div>
                        <div className="md:col-span-6 col-span-full">
                          <label
                            htmlFor="description"
                            className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                          >
                            Is additional support being provided? <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="mt-2 relative rounded-md shadow-sm">
                            <MultiSelect
                              options={optionsforOtherAgenciedSupporting}
                              value={selectedOptionSupporting}
                              onChange={setSelectedOptionSupporting}
                              labelledBy="Select"
                              isCreatable={true}
                              hasSelectAll={false}
                            />
                          </div>
                        </div>
                        <div className="col-span-full">
                          <label
                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                          >
                            Is there an immediate risk that the conflict between the participants will escalate? <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="float-right">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                              <input
                                type="radio"
                                name="particioantsEscalate"
                                id="yes_particioantsEscalate"
                                value="Yes"
                                onChange={handleChangeMainForm}
                                checked={mainFormData.particioantsEscalate === `Yes`}
                                hidden
                              />
                              <label
                                htmlFor="yes_particioantsEscalate"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                              <input
                                type="radio"
                                name="particioantsEscalate"
                                id="no_particioantsEscalate"
                                value="No"
                                onChange={handleChangeMainForm}
                                checked={mainFormData.particioantsEscalate === `No`}
                                hidden
                              />
                              <label
                                htmlFor="no_particioantsEscalate"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-full inline-block">
                          <label
                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                          >
                            Are the participants willing to resolve this issue? <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="float-right">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                              <input
                                type="radio"
                                name="willingToResolve"
                                id="yes_willingToResolve"
                                value="Yes"
                                onChange={handleChangeMainForm}
                                checked={mainFormData.willingToResolve === `Yes`}
                                hidden
                              />
                              <label
                                htmlFor="yes_willingToResolve"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                              <input
                                type="radio"
                                name="willingToResolve"
                                id="no_willingToResolve"
                                value="No"
                                onChange={handleChangeMainForm}
                                checked={mainFormData.willingToResolve === `No`}
                                hidden
                              />
                              <label
                                htmlFor="no_willingToResolve"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-full inline-block">
                          <label
                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                          >
                            Do you suspect that drugs / alcohol were involved? <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="float-right">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                              <input
                                type="radio"
                                name="suspectAlcohol"
                                id="yes_suspectAlcohol"
                                value="Yes"
                                onChange={handleChangeMainForm}
                                checked={mainFormData.suspectAlcohol === `Yes`}
                                hidden
                              />
                              <label
                                htmlFor="yes_suspectAlcohol"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                              <input
                                type="radio"
                                name="suspectAlcohol"
                                id="no_suspectAlcohol"
                                value="No"
                                onChange={handleChangeMainForm}
                                checked={mainFormData.suspectAlcohol === `No`}
                                hidden
                              />
                              <label
                                htmlFor="no_suspectAlcohol"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
                {count == 3 ? <div>

                  <div className="justify-center mt-3 mb-4 md:mb-7">
                    <p className="text-2xl md:text-3xl xl:text-4xl 3xl:text-5xl font-bold text-center ">
                      Possible solutions
                    </p>
                    <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                    <div className="grid grid-cols-12 gap-3 md:gap-5">
                      <div className="col-span-12">
                        <div className="inline-grid grid-cols-12 gap-4 w-full">
                          <p className="sm:col-span-5 col-span-full text-[1.1rem] font-semibold my-2 text-right text-gray-600">
                            Which solutions can we agree on between?
                          </p>
                          <select
                            onChange={handleChangeSolutionDataforTable}
                            // value={rowsDataSolutionTemp.name}
                            name='name'
                            id='mainAgreementName'
                            className="sm:col-span-3 col-span-12 bg-gray-50 border border-gray-300 text-gray-900 text-[0.95rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          >
                            <option value="" />
                            {users.map((e, key) => {
                              return (
                                <option id={e.id} value={e.id}>
                                  {e.firstName + ' ' + e.lastName}
                                </option>
                              );
                            })}
                          </select>
                          <p className="sm:col-span-5 col-span-full text-[1.1rem] font-semibold my-2 text-right text-gray-600">
                            and
                          </p>
                          <select
                            onChange={handleChangeSolutionDataforTable}
                            // value={rowsDataSolutionTemp.name2} 
                            name='name2'
                            id='mainAgreementName2'
                            className="sm:col-span-3 col-span-12 bg-gray-50 border border-gray-300 text-gray-900 text-[0.95rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          >
                            <option value="" />
                            {users.map((e, key) => {
                              return (
                                <option id={e.id} value={e.id}>
                                  {e.firstName + ' ' + e.lastName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <ul className="grid gap-4 w-full md:grid-cols-3 lg:grid-cols-4 mt-6">

                          {tempArrayForSelectedSolutions.map((e, key) => {
                            // {optionsforSolutions.map((e, key) => {
                            return (
                              <li key={key}>
                                <input
                                  type="checkbox"
                                  id={"ag" + key}
                                  className="hidden peer"
                                  name='agreeingSolutionCheckbox'
                                  onChange={handleChangeForCheckboxs}
                                  value={e}
                                />
                                <label
                                  htmlFor={"ag" + key}
                                  className="inline-flex justify-between items-center p-3 w-full h-full text-gray-600 bg-white rounded-lg border-2 border-gray-200 cursor-pointer peer-checked:border-green-600 hover:text-gray-800 peer-checked:text-gray-800 hover:bg-gray-50"
                                >
                                  <div className="block">
                                    <div className="w-full text-[0.95rem] font-medium">
                                      {e}
                                    </div>
                                  </div>
                                </label>
                              </li>
                            );
                          })}
                          <li>
                            <input
                              type="checkbox"
                              id="input1"
                              className="hidden peer"
                              value={otherCheckBox1}
                              name='agreeingSolutionCheckbox'
                              onChange={handleChangeForCheckboxs}
                            />
                            <label
                              htmlFor="input1"
                              className="inline-flex justify-between items-center p-3 w-full h-full text-gray-600 bg-white rounded-lg border-2 border-gray-200 cursor-pointer peer-checked:border-green-600 hover:text-gray-800 peer-checked:text-gray-800 hover:bg-gray-50"
                            >
                              <div className="block w-full">
                                Other
                                <input
                                  onChange={e => setOtherCheckBox1(e.target.value)}
                                  type="text"
                                  placeholder="enter here"
                                  className="w-full border-2 border-gray-200 p-1"
                                ></input>
                              </div>
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="input2"
                              className="hidden peer"
                              value={otherCheckBox2}
                              name='agreeingSolutionCheckbox'
                              onChange={handleChangeForCheckboxs}
                            />
                            <label
                              htmlFor="input2"
                              className="inline-flex justify-between items-center p-3 w-full h-full text-gray-600 bg-white rounded-lg border-2 border-gray-200 cursor-pointer peer-checked:border-green-600 hover:text-gray-800 peer-checked:text-gray-800 hover:bg-gray-50"
                            >
                              <div className="block w-full">
                                Other
                                <input
                                  type="text"
                                  placeholder="enter here"
                                  className="w-full border-2 border-gray-200 p-1"
                                  onChange={e => setOtherCheckBox2(e.target.value)}
                                />
                              </div>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-span-full text-center mt-4">
                      <button
                        type="button"
                        onClick={addSolutionTableRows}
                        className="inline-flex max-h-12 bottom-0 my-auto items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md bg-green-600 hover:bg-green-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                      >
                        <span>Add</span>
                      </button>
                    </div>
                    {rowsDataSolution.length > 0 ? (
                      <div className="mt-8">
                        <div className=" -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block py-2 align-middle md:px-6 lg:px-8 w-full">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                              <table className="min-w-full table-auto divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 w-[20%] text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                                    >
                                      First Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 w-[20%] text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Second Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 w-[50%] text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Solutions
                                    </th>
                                    <th className="w-[10%]">
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {rowsDataSolution.map((e, key) => {
                                    return (<tr key={e.name}>
                                      <td className="py-4 pl-4 pr-3 text-[0.95rem] sm:pl-6">
                                        <div className="flex items-center">
                                          <div className="ml-2">
                                            <div className="font-medium text-gray-900">
                                              {users.map((user, key) => {
                                                if (user.id == e.name) {
                                                  return (
                                                    <>{user.firstName + ' ' + user.lastName}</>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className=" py-4 pl-4 pr-3 text-[0.95rem] sm:pl-6">
                                        <div className="flex items-center">
                                          <div className="ml-2">
                                            <div className="font-medium text-gray-900">
                                              {users.map((user, key) => {
                                                if (user.id == e.name2) {
                                                  return (
                                                    <>{user.firstName + ' ' + user.lastName}</>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="py-4 pl-4 pr-3 text-[0.95rem] sm:pl-6">
                                        <div className="flex items-center">
                                          <div className="ml-2">
                                            <div className="font-medium text-gray-900">
                                              {e.solutions.join(',')}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div className="flex items-center">
                                          <div className="ml-2">
                                            <div className="font-medium text-gray-900">
                                              <button className="ml-4 inline-block align-middle select-none font-normal text-sm whitespace-no-wrap rounded px-1 text-gray-800" onClick={() => (deleteSolutionTableRows(key))}><XCircleIcon className="w-6 h-6" aria-hidden="true" /></button>

                                              {/* <button className="inline-block align-middle select-none border font-normal text-xs whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={() => (deleteSolutionTableRows(key))}>x</button> */}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : ''
                    }
                  </div>
                </div> : ""}
                {count == 4 ? <>
                  <div className="justify-center mt-3 mb-4 md:mb-7">
                    <p className="text-2xl md:text-3xl xl:text-4xl 3xl:text-5xl font-bold text-center ">
                      Agreeing solutions for PDF
                    </p>
                    <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                    <p className="block text-[1.1rem] font-semibold text-gray-600">
                      Click on the pair to view agree solutions and print out for signature.
                    </p>
                    <div className="grid grid-cols-12 gap-3 md:gap-5 mt-4">
                      {console.log(agreementPDFs)}
                      {agreementPDFs.map((files) => {
                        return (
                          <div className="col-span-12 md:col-span-4 ">
                            <div className="rounded overflow-hidden shadow-lg">
                              <a href={files.url} target='_blank' rel='noopener noreferrer' className={"px-4 py-2 items-center text-[green] flex flex-col border-2 rounded-md border-gray-600 h-full w-full hover:bg-gray-200"}>
                                {files.fileName}
                              </a>
                            </div>
                          </div>
                        );

                      })}

                    </div>
                  </div>
                </>
                  : ''}
                {count == 5 ? <>
                  <div className="justify-center mt-3 mb-4 md:mb-7">
                    <p className="text-xl md:text-2xl font-bold text-center">
                      Participant feedback
                    </p>
                    <div className="flex my-4 md:my-6">
                      <button
                        onClick={sendParticipantFeedback}
                        type="button"
                        id="addPerson"
                        className={mainFormData.feedbackStatus == '' ? "inline-flex text-[1.1rem] font-semibold mx-auto rounded-md text-gray-600 max-h-12 bottom-0 my-auto items-center px-6 py-2 border-2 border-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2" : 'inline-flex text-[1.1rem] font-semibold mx-auto rounded-md max-h-12 bottom-0 my-auto items-center px-6 py-2 border-2 border-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-800'}
                      >
                        <span>Send Feedback forms to participants</span>
                      </button>
                    </div>
                    <div className="flex my-4 md:my-6 mx-auto">
                      <button
                        onClick={closeCaseBtn}
                        type="button"
                        id="addPerson"
                        className="inline-flex text-[1.1rem] font-semibold mx-auto rounded-md text-gray-600 max-h-12 bottom-0 my-auto items-center px-6 py-2 border-2 border-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                      >
                        <span>Close Case</span>
                      </button>
                    </div>
                  </div>
                </> : ''}
              </div>
            </div>
          </div>
          <div className="col-span-full text-center mt-4">
            {count > 1 ? (
              <button
                id="prevBtn"
                type="button"
                onClick={prevStep}
                className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-semibold rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
              >
                <span>PREVIOUS </span>
              </button>
            ) : null}
            <button
              type="button"
              onClick={() => {
                Continue(mainFormData)
              }}
              id="nextBtn"
              className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-semibold rounded-md shadow-md text-white button focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {count < 5 ? (<span>NEXT </span>) : (<span>Complete </span>)}
            </button>
          </div>
        </CurrentUser.Provider>
      </UserContext.Provider>
    </div>
  );
};

export default Quickform;