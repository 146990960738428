import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Incidents from "../pages/my_incidents";
import Allocate from "../pages/allocate_incident";
import Admin from "../pages/admin";
import IncidentDetails from "../pages/incidentDetails";
import Navbar from "../component/Navbar";
import ProtectedRoutes from "../component/ProtectedRoutes";
import Fullform from "../component/full-case/user-info/Fullform";
import Inquiry from "../component/extra";
import Quickform from "../component/quick-case/user-info/Quickform";
import Summary from "../pages/reports/Summary";
import Intelligence from "../pages/reports/Intelligence";
import Trends from "../pages/reports/Trends";
import Management from "../pages/reports/Management";
import IncidentsData from "../pages/reports/IncidentsData";

export default function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/*" element={<Incidents />} />
          <Route path="/" element={<Incidents />} />
          <Route path='/fullcase/:_id' element={<Fullform />} />
          <Route path='/quickcase/:_id' element={<Quickform />} />
          <Route path='/extra' element={<Inquiry />} />
          <Route path='/incidentDetails/:_id' element={<IncidentDetails />} />
          {/* <Route path="/dashboard" element={<Navigate to="/dashboard/summary" element={Summary}/>}> */}
          <Route path="/dashboard" element={<Dashboard />} >
          <Route path="" element={<Summary />} />
            <Route path="summary" element={<Summary />} />
            <Route path="incidentsData" element={<IncidentsData />} />
            <Route path="intelligence" element={<Intelligence />} />
            <Route path="trends" element={<Trends />} />
            <Route path="management" element={<Management />} />
          </Route>
          <Route path="/my_incidents" element={<Incidents />} />
          <Route path="/allocate_incident" element={<Allocate />} />
          <Route path="/admin" element={<Admin />} />
        </Route>
      </Routes>
    </>
  );
}
