import React, { useState } from "react"
import { useEffect } from "react";
import { decodeToken } from "react-jwt";
import { getCookie } from "../utils/cookie/cookie";



export default function NewCaseDescription(props) {

    const intialFormData = Object.freeze({ incidentType: props.formType, formStatus: 'active', formStatusReason: {}, feedbackStatus: '', admin: null, user: null, _id: null, description: '', users: [], referralsMade: [], agenciesSupporting: [], particioantsEscalate: '', willingToResolve: '', suspectAlcohol: '', classification: [], externalProfessional: '', successResolution: '', balanceRepresentation: '', speakingOrder: [], agreegingSolution: [] })

    const [mainFormData, updateMainFormData] = React.useState(intialFormData);

    console.log(props.formType);

    const handleChangeUserForm = (e) => {
        updateMainFormData({
            ...mainFormData,
            [e.target.name]: e.target.value
        });
    };

    const popupUserFormClose = () => {
        props.popupClose();
    }

    const handleSubmitDescription = (e) => {
        e.preventDefault()
        props.onSubmit(mainFormData)
        console.log(mainFormData);
    };
    useEffect(() => {
        const authorization = getCookie('authorization')
        const decodedToken = decodeToken(authorization);
        if (decodedToken.role == 'Administrator') {
            updateMainFormData({
                ...mainFormData,
                admin: decodedToken.id,
                user: decodedToken.id
            });
            console.log(decodedToken.id);
        }
        else {
            updateMainFormData({
                ...mainFormData,
                user: decodedToken.id
            });
        }
    }, []);



    return (
        <>
            <form onSubmit={handleSubmitDescription} className='w-full'>
                <div className="mt-3 text-center ">
                    <div className="grid grid-cols-12 gap-2 md:gap-5 my-2">
                        <div className="md:col-span-12 col-span-full">
                            <label
                                htmlFor="description"
                                className="block text-lg font-bold text-gray-600"
                            >
                                Please enter a description for the incident <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <textarea
                                    type="text"
                                    name="description"
                                    id="description"
                                    required
                                    onChange={handleChangeUserForm}
                                    className="text-gray-700 mt-2 block w-full h-32 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-base"
                                    value={mainFormData.description}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        id="addPersonDetails"
                        type="submit"
                        className="px-5 py-2 m-2 bg-green-600 hover:bg-green-800 text-white cursor-pointer rounded-md"
                    >
                        Next
                    </button>
                    <button
                        onClick={popupUserFormClose}
                        id="closePerson"
                        type="button"
                        className="px-5 py-2 m-2 bg-black text-white cursor-pointer rounded-md"
                    >
                        Close
                    </button>
                </div>
            </form>
        </>
    )
};