import "./App.css";
import Login from "./pages/login";
import Layout from './MainLayout/Layout'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Password_set from "./pages/password_set";
import ParticipantFeedback from "./pages/participantsFeedback";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/example" element={<Example />} />
          <Route path="/form" element={<FullForm />} />
          <Route path="/subform" element={<Form />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Layout />} />
          <Route path="/set-password" element={<Password_set />} />
          <Route path="/feedback/:_caseId/:_participantId" element={<ParticipantFeedback />} />
          {/* <Route path="/participant-feedback/:_id" element={<ParticipantFeedback />} /> */}

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
