import React, { useState, useContext, useEffect, Fragment } from "react";
import { MultiSelect } from "react-multi-select-component";
import Swal from "sweetalert2";
import Userform from "../../Userform";
import { UserContext } from "./Fullform";
import { CurrentUser } from "./Fullform";
import 'react-dropdown-tree-select/dist/styles.css'
import Container from "../../TreeSelect/DropdownContainer";
import logo from "../../../logos/logo.png";
import PauseCase from "../../PauseCase";
import EscalateCase from "../../EscalateCase";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuAlt1Icon, XCircleIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";



const UserDetailForm = (props) => {

  const users = useContext(UserContext)
  const currentUserForSubForm = useContext(CurrentUser);
  const [step, setstep] = useState(1);
  const [userForm, setUserForm] = useState(false);
  const intialSubFormData = Object.freeze({ areYouComfortable: '', whatHappened: '', beforeIncident: [], duringIncident: [], afterIncident: [], physicalLocation: '', digitalLocation: '', dateOfIncident: '', timeOfIncident: '', presentCharacteristics: [], Conflict: [], drugs: '', solutions: [], feedback: {} })

  const currentUserData = () => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].id == currentUserForSubForm) {
        return users[i].data
      }
    }
    return intialSubFormData
  }

  const currentUserInfo = () => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].id == currentUserForSubForm) {
        return users[i]
      }
    }
  }

  const infoFun = () => {
    let msg = '';
    msg = `Try to help the participant link emotions to key events.

      This helps them understand different feelings.
      
      Its possible to select/deselect multiple options by simply clicking on
      them.
      
      Please email your questions to us at support@insightbox.com`

    if (msg.length > 0) {
      Swal.fire({
        text: msg,
        showConfirmButton: false,
      })
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }



  // const [subFormData, updateSubFormData] = React.useState(intialSubFormData);
  const [subFormData, updateSubFormData] = React.useState(currentUserData);

  const [selectedOption1, setSelectedOption1] = useState(subFormData.beforeIncident);
  const [selectedOption2, setSelectedOption2] = useState(subFormData.duringIncident);
  const [selectedOption3, setSelectedOption3] = useState(subFormData.afterIncident);
  const [selectedOptioncharacteristics, setSelectedOptioncharacteristics] = useState(subFormData.presentCharacteristics);
  const [selectedOptionConflict, setSelectedOptionConflict] = useState(subFormData.Conflict);

  const dataForTree = [
    { label: `Don't know`, value: `Don't know` },
    { label: `Extremist Views`, value: `Extremist Views` },
    { label: `Physical`, value: `Physical` },
    { label: `Social Isolation`, value: `Social Isolation` },
    { label: `Sexting`, value: `Sexting` },
    { label: `Theft`, value: `Theft` },
    { label: `Verbal`, value: `Verbal` },
    { label: 'Cyber/Social Media', value: 'Cyber/Social Media', children: [{ label: `Facebook`, value: `Facebook` },{ label: `FB Messenger`, value: `FB Messenger` },{ label: `Flickr`, value: `Flickr` }, { label: `Instagram`, value: `Instagram` },{ label: `Kik`, value: `Kik` }, { label: `Pinterest`, value: `Pinterest` },{ label: `Snapchat`, value: `Snapchat` }, { label: `Tinder`, value: `Tinder` },{ label: `Twitter`, value: `Twitter` },{ label: `WhatsApp`, value: `WhatsApp` },   { label: `Youtube`, value: `Youtube` },   { label: `Other`, value: `Other` }] },
    { label: `Other`, value: `Other` },
  ]

  function deepSearch(object, key, predicate) {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

    for (let i = 0; i < Object.keys(object).length; i++) {
      let value = object[Object.keys(object)[i]];
      if (typeof value === "object" && value != null) {
        let o = deepSearch(object[Object.keys(object)[i]], key, predicate)
        if (o != null) {
          o["checked"] = true
          return object
        }
      }
    }
    return null
  }


  const conflictDataTable = () => {
    var tempData = dataForTree
    for (let i = 0; i < selectedOptionConflict.length; i++) {
      tempData = deepSearch(tempData, 'label', (k, v) => v === selectedOptionConflict[i].label)
    }
    return tempData
  }
  const [optionsConflict, setOptionsConflict] = useState(conflictDataTable())

  // for multistep multiselect dropdown 
  // setSelectedOptionConflict2([])
  const onChange = (currentNode, selectedNodes) => {
    setSelectedOptionConflict(
      [...selectedNodes]
    );

  }

  // for custom other checkbox value for solutions
  const [otherCheckBox1, setOtherCheckBox1] = useState('');
  const [otherCheckBox2, setOtherCheckBox2] = useState('');

  // for solution table
  const [rowsData, setRowsData] = useState(subFormData.solutions);
  const intialRowData = Object.freeze({ name: '', solutions: [] })
  const [rowsDataTemp, setRowsDataTemp] = React.useState(intialRowData);

  const handleChangeDataforTable = (e) => {
    setRowsDataTemp({
      ...rowsDataTemp,
      [e.target.name]: e.target.value
    });
    console.log(e.target.value)
  }

  const handleChangeForCheckboxs = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { solutions } = rowsDataTemp;

    if (checked) {
      setRowsDataTemp({
        ...rowsDataTemp,
        solutions: [...solutions, value],
      });
    }
    else {
      setRowsDataTemp({
        ...rowsDataTemp,
        solutions: solutions.filter((e) => e !== value),
      });
    }
  };

  const addTableRows = (e) => {
    e.preventDefault();
    if (rowsDataTemp.name != '') {
      let flag = false;
      rowsData.map((e) => {
        if (rowsDataTemp.name == e.name) {
          flag = true
        }
      })
      if (flag) {
        document.getElementById("AgreementSelectMenu").selectedIndex = 0
        document.querySelectorAll('input[name=solutionCheckbox]').forEach(el => el.checked = false);
        setRowsDataTemp(intialRowData)
        Swal.fire({
          text: 'Please select other user',
        })
      }
      else if (rowsDataTemp.solutions.length >= 2) {
        setRowsData(rowsData => [...rowsData, rowsDataTemp]);
        console.log(rowsDataTemp)
        setRowsDataTemp(intialRowData)
        document.querySelectorAll('input[name=solutionCheckbox]').forEach(el => el.checked = false);
        
      } else {
        Swal.fire({
          text: 'Please select minimum 2 solutions before continuing',
        })
      }
    } else {
      Swal.fire({
        text: 'Please select the person from the dropdown before continuing',
      })
    }

  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }


  const handleChangeSubForm = (e) => {
    console.log("handle sub form ")
    console.log(e.target.name)
    console.log(e.target.value)
    updateSubFormData({
      ...subFormData,
      [e.target.name]: e.target.value
    });
  };

  const prevStep = () => {
    setstep(step - 1);
  };

  const nextStep = () => {
    setstep(step + 1);
  };
  const Continue = (e) => {
    e.preventDefault();
    console.log(subFormData)
    if (step == 2) {
      updateSubFormData({
        ...subFormData,
        beforeIncident: selectedOption1,
        duringIncident: selectedOption2,
        afterIncident: selectedOption3
      })
      console.log(subFormData)
      nextStep();
    }
    else if (step == 4) {
      console.log(selectedOptionConflict)
      console.log(optionsConflict)

      var tempData = optionsConflict
      for (let i = 0; i < selectedOptionConflict.length; i++) {
        console.log(selectedOptionConflict[i].label)
        tempData = deepSearch(tempData, 'label', (k, v) => v === selectedOptionConflict[i].label)
      }
      setOptionsConflict(
        [...tempData]
      )
      // updateSubFormData({
      //   ...subFormData,
      //   Conflict: selectedOptionConflict,
      // })
      updateSubFormData({
        ...subFormData,
        presentCharacteristics: selectedOptioncharacteristics,
        Conflict: selectedOptionConflict,
      })
      nextStep();
    }
    else if (step == 5) {
      Swal.fire({
        text: "Do you need anything else from anyone else in order to move on?",
        showCancelButton: true,
        confirmButtonColor: 'white',
        cancelButtonColor: 'black',
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
      }).then((result) => {
        if (!result.isConfirmed) {
          updateSubFormData({
            ...subFormData,
            solutions: rowsData
          })
          console.log(subFormData)
          nextStep();
        }
      })
    }
    else {
      console.log(subFormData)
      nextStep();
    }
  };
  const popupUserForm = () => setUserForm(true);
  const popupUserFormClose = () => setUserForm(false);

  const getUserFormData = (data) => {
    console.log("props data")
    console.log(data)
    props.onUserFormSubmit(data)
    setUserForm(false);
  }

  const popupSubFormClose = () => {
    props.onSubFormClose();
  }

  const SubmitSubForm = (e) => {
    e.preventDefault();
    console.log(subFormData)
    props.onSubmit(subFormData)
  }

  const [pauseCaseDiv, setPauseCaseDiv] = useState(false);

  const popupPauseCaseDiv = () => setPauseCaseDiv(true);
  const popupPauseCaseDivClose = () => setPauseCaseDiv(false);

  const [escalateCaseDiv, setEscalateCaseDiv] = useState(false);

  const popupEscalateCaseDiv = () => setEscalateCaseDiv(true);
  const popupEscalateCaseDivClose = () => setEscalateCaseDiv(false);

  const pauseCase = () => {
    popupPauseCaseDivClose()
    props.onPauseCase();
  }


  const triageCase = () => {
    props.onTriageCase();
  }

  const escalatedCase = () => {
    popupEscalateCaseDivClose()
    props.onEscalatedCase();
  }

  var navigate = useNavigate();

  const HomeFun = () => navigate("/my_incidents");

  useEffect(() => {
    if (step == 1) {
      Swal.fire({
        title: 'Question for the Practitioner',
        text: "Do you consider yourself to be impartial & are you comfortable dealing with this process and the people involved?",
        showCancelButton: true,
        confirmButtonColor: 'white',
        cancelButtonColor: 'black',
        confirmButtonText: `Yes I'm comfortable`,
        cancelButtonText: "No, I'll return this case to Triage",
      }).then((result) => {
        if (result.isConfirmed) {
          updateSubFormData({
            ...subFormData,
            areYouComfortable: `Yes I'm comfortable`
          });
        } else {
          updateSubFormData({
            ...subFormData,
            areYouComfortable: `No, I'll return this case to Triage`
          });
          // pauseCase();
          triageCase();

        }
      })
    }
  }, [])



  switch (step) {
    case 1:
      return (
        <div>
          <div className="font-medium justify-center items-center flex text-gray-900 w-full p-4">
            <img className="h-20 w-100% 3xl:h-32 m-auto" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col p-4 md:p-8  h-full">

            <div className="font-medium justify-end items-end flex text-gray-900">
              <div className="md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xsrounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <MenuAlt1Icon
                          className="w-6 h-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="Info">
                          {({ active }) => (
                            <button
                              onClick={infoFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Info
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Pause">
                          {({ active }) => (
                            <button
                              onClick={popupPauseCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Pause
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Escalate">
                          {({ active }) => (
                            <button
                              onClick={popupEscalateCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Escalate
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Home">
                          {({ active }) => (
                            <button
                              onClick={HomeFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Home
                            </button>
                          )}
                        </Menu.Item>
                        {/* ))} */}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                </div>
              </div>
              {pauseCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <PauseCase onSubmit={pauseCase} popupClose={popupPauseCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              {escalateCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-y-auto flex-1 min-h-screen fixed inset-0 flex items-center w-fit my-2 md:max-w-[70%] mx-auto z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <EscalateCase onSubmit={escalatedCase} popupClose={popupEscalateCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              <button className="ml-4 inline-block align-middle select-none font-normal text-sm whitespace-no-wrap rounded px-1 text-gray-800" onClick={popupSubFormClose}><XCircleIcon
                className="w-6 h-6"
                aria-hidden="true"
              /></button>
              {/* <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button> */}
            </div>
            <div className="justify-center mt-3 mb-4 md:mb-6">
              <p className="text-2xl md:text-3xl 3xl:text-4xl font-bold text-center  mainTitle">
                What happened?
              </p>
              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
            </div>
            <div className="grid grid-cols-12 gap-5 md:gap-8 p-4 md:p-10 my-auto">
              <div className="md:col-span-12 col-span-full">
                <label
                  htmlFor="whatHappened"
                  className="block text-[1.1rem] font-semibold my-4 text-gray-600"
                >
                  {currentUserInfo().firstName ? currentUserInfo().firstName : ''} can you tell me what happened?
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <textarea
                    type="text"
                    name="whatHappened"
                    id="whatHappened"
                    onChange={handleChangeSubForm}
                    required
                    className="text-gray-700 h-36 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1.1rem]"
                    defaultValue={subFormData.whatHappened}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-full text-center mt-auto mx-auto flex items-center">
              <button
                type="submit"
                onClick={Continue}
                id="nextBtn"
                className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white button bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span>NEXT </span>
              </button>

            </div>
          </div>
        </div>
      );
    case 2:
      const options = [
        { value: "Angry", label: "Angry" },
        { value: "Disgusted", label: "Disgusted" },
        { value: "Embarrassed", label: "Embarrassed" },
        { value: "Scared", label: "Scared" },
        { value: "Happy", label: "Happy" },
        { value: "Jealous", label: "Jealous" },
        { value: "Neutral", label: "Neutral" },
        { value: "Sad", label: "Sad" },
        { value: "Surprised", label: "Surprised" },
      ];

      return (
        <div>
          <div className="font-medium justify-center items-center flex text-gray-900 bg-black w-full">
            <img className="h-14 w-100% 3xl:h-24 m-auto" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col p-4 md:p-8  h-full">
          <div className="font-medium justify-end items-end flex text-gray-900">
              <div className="md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xsrounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <MenuAlt1Icon
                          className="w-6 h-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="Info">
                          {({ active }) => (
                            <button
                              onClick={infoFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Info
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Pause">
                          {({ active }) => (
                            <button
                              onClick={popupPauseCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Pause
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Escalate">
                          {({ active }) => (
                            <button
                              onClick={popupEscalateCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Escalate
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Home">
                          {({ active }) => (
                            <button
                              onClick={HomeFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Home
                            </button>
                          )}
                        </Menu.Item>
                        {/* ))} */}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                </div>
              </div>
              {pauseCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <PauseCase onSubmit={pauseCase} popupClose={popupPauseCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              {escalateCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-y-auto flex-1 min-h-screen fixed inset-0 flex items-center w-fit my-2 md:max-w-[70%] mx-auto z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <EscalateCase onSubmit={escalatedCase} popupClose={popupEscalateCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              <button className="ml-4 inline-block align-middle select-none font-normal text-sm whitespace-no-wrap rounded px-1 text-gray-800" onClick={popupSubFormClose}><XCircleIcon
                className="w-6 h-6"
                aria-hidden="true"
              /></button>
              {/* <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button> */}
            </div>
            {/* <div className="font-medium justify-end items-end flex text-gray-900">

              <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button>
            </div> */}
            <div className="justify-center mt-3 mb-4 md:mb-6">
              <p className="text-2xl md:text-3xl 3xl:text-4xl font-bold text-center  mainTitle">
                How did you feel?
              </p>
              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
            </div>

            <div className="grid grid-cols-12 gap-5 md:gap-8 m-8">
              <div className="sm:col-span-4 col-span-full">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  Before the incident?
                </label>
                <MultiSelect
                  options={options}
                  value={selectedOption1}
                  labelledBy="Select"
                  isCreatable={true}
                  hasSelectAll={false}
                  onChange={setSelectedOption1}
                  ClearIcon={''}
                />
              </div>
              <div className="sm:col-span-4 col-span-full">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  During the incident?
                </label>
                <MultiSelect
                  options={options}
                  value={selectedOption2}
                  onChange={setSelectedOption2}
                  labelledBy="Select"
                  isCreatable={true}
                  hasSelectAll={false}
                />
              </div>
              <div className="sm:col-span-4 col-span-full">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  After the incident?
                </label>
                <MultiSelect
                  options={options}
                  value={selectedOption3}
                  onChange={setSelectedOption3}
                  labelledBy="Select"
                  isCreatable={true}
                  hasSelectAll={false}
                />
              </div>
            </div>
            <div className="m-8">
              <p className="block text-[1.1rem] font-semibold m-auto text-gray-600">
                Who else was involved, or has been affected by this incident
                <br />
                <button
                  onClick={popupUserForm}
                  type="button"
                  id="addPerson"
                  className="mx-auto mt-2 my-4 inline-flex items-center px-6 py-2 border border-transparent text-[1rem] font-semibold m-auto text-gray-600 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span>+ Add another person</span>
                </button>
              </p>
            </div>
            {userForm ? (
              <>
                <div
                  id="overlay"
                  className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                />
                <div
                  id="dialogForPerson"
                  className="overflow-x-hidden overflow-y-auto flex-1 min-h-fit mx-auto sm:w-3/4 md:w-5/8 fixed inset-x-0 top-1/4 z-50 bg-white rounded-md md:px-8 md:py-6 sm:p-2 space-y-5 drop-shadow-lg"
                >
                  <Userform onSubmit={getUserFormData} popupClose={popupUserFormClose} />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="col-span-full text-center mt-auto mx-auto flex items-center">
              <button
                id="prevBtn"
                type="button"
                onClick={prevStep}
                className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
              >
                <span>PREVIOUS </span>
              </button>
              <button
                type="submit"
                onClick={Continue}
                id="nextBtn"
                className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white button focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span>NEXT </span>
              </button>

            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div>
          <div className="font-medium justify-center items-center flex text-gray-900 bg-black w-full">
            <img className="h-14 w-100% 3xl:h-24 m-auto" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col p-4 md:p-8  h-full">
          <div className="font-medium justify-end items-end flex text-gray-900">
              <div className="md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xsrounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <MenuAlt1Icon
                          className="w-6 h-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="Info">
                          {({ active }) => (
                            <button
                              onClick={infoFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Info
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Pause">
                          {({ active }) => (
                            <button
                              onClick={popupPauseCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Pause
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Escalate">
                          {({ active }) => (
                            <button
                              onClick={popupEscalateCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Escalate
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Home">
                          {({ active }) => (
                            <button
                              onClick={HomeFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Home
                            </button>
                          )}
                        </Menu.Item>
                        {/* ))} */}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                </div>
              </div>
              {pauseCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <PauseCase onSubmit={pauseCase} popupClose={popupPauseCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              {escalateCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-y-auto flex-1 min-h-screen fixed inset-0 flex items-center w-fit my-2 md:max-w-[70%] mx-auto z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <EscalateCase onSubmit={escalatedCase} popupClose={popupEscalateCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              <button className="ml-4 inline-block align-middle select-none font-normal text-sm whitespace-no-wrap rounded px-1 text-gray-800" onClick={popupSubFormClose}><XCircleIcon
                className="w-6 h-6"
                aria-hidden="true"
              /></button>
              {/* <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button> */}
            </div>
            {/* <div className="font-medium justify-end items-end flex text-gray-900">
              <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button>
            </div> */}
            <div className="justify-center mt-3 mb-4 md:mb-6">
              <p className="text-2xl md:text-3xl 3xl:text-4xl font-bold text-center  mainTitle">
                Where and when
              </p>
              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
            </div>

            <div className="grid grid-cols-12 gap-5 md:gap-8 m-8">
              <div className="sm:col-span-6 col-span-full ">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  Physical Location
                </label>
                <select
                  onChange={handleChangeSubForm}
                  value={subFormData.physicalLocation}
                  id="physicalLocation"
                  name="physicalLocation"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                >
                  <option value="">Choose any</option>
                  <option value="IN SCHOOL">IN SCHOOL</option>
                  <option value="Classroom">Classroom</option>
                  <option value="Corridor / hallway">Corridor / hallway</option>
                  <option value="Changing Room">Changing Room</option>
                  <option value="Lunch hall">Lunch hall</option>
                  <option value="MUGA">MUGA</option>
                  <option value="Outside space">Outside space</option>
                  <option value="School gate">School gate</option>
                  <option value="Sportshall">Sportshall</option>
                  <option value="Staircase">Staircase</option>
                  <option value="Other">Other</option>
                  <option value="TRANSPORT">TRANSPORT</option>
                  <option value="Bike">Bike</option>
                  <option value="Public Bus">Public Bus</option>
                  <option value="School Bus">School Bus</option>
                  <option value="Train">Train</option>
                  <option value="Tram">Tram</option>
                  <option value="Walking to/from school">Walking to/from school</option>
                  <option value="OUTSIDE OF SCHOOL">OUTSIDE OF SCHOOL</option>
                  <option value="Home">Home</option>
                  <option value="Community Southern Cross">Community Southern Cross</option>
                  <option value="Community Meadehill">Community Meadehill</option>
                  <option value="Community Castleford Campus">Community Castleford Campus</option>
                  {/* <option value="Regional Office">Regional Office</option>
                  <option value="Event Operations">Event Operations</option>
                  <option value="County Operations">County Operations</option>
                  <option value="Ambulance Operations">Ambulance Operations</option>
                  <option value="Other">Other</option> */}
                </select>
              </div>
              <div className="sm:col-span-6 col-span-full">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  Digital
                </label>
                <select
                  value={subFormData.digitalLocation}
                  onChange={handleChangeSubForm}
                  id="digitalLocation"
                  name="digitalLocation"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                >
                  <option value="">Choose any</option>
                  {/* <option value="On-Line meeting e.g. Teams">
                    On-Line meeting e.g. Teams
                  </option> */}
                  <option value="On-line lesson">On-line lesson</option>
                  {/* <option value="Phone Call">Phone Call</option>
                  <option value="Messaging">Messaging</option>
                  <option value="Social media">Social media</option>
                  <option value="Other">Other</option> */}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-5 md:gap-8 m-8">
              <div className="sm:col-span-6 col-span-full">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  Date of incident
                </label>
                <input
                  value={subFormData.dateOfIncident}
                  onChange={handleChangeSubForm}
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                  id="dateOfIncident"
                  name="dateOfIncident"
                />
              </div>
              <div className="sm:col-span-6 col-span-full">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  Time of incident
                </label>
                <input
                  value={subFormData.timeOfIncident}
                  onChange={handleChangeSubForm}
                  type="time"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                  id="timeOfIncident"
                  name="timeOfIncident"
                />
              </div>


            </div>

            <div className="col-span-full text-center mt-auto mx-auto flex items-center">
              <button
                id="prevBtn"
                type="button"
                onClick={prevStep}
                className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
              >
                <span>PREVIOUS </span>
              </button>
              <button
                type="submit"
                onClick={Continue}
                id="nextBtn"
                className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white button focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span>NEXT </span>
              </button>

            </div>
          </div>
        </div>
      );
    case 4:
      const optionsforcharacteristics = [
        { value: "Appearance", label: "Appearance" },
        { value: "Biphobic", label: "Biphobic" },
        { value: "Disability", label: "Disability" },
        { value: "Don’t know", label: "Don’t know" },
        { value: "Facial disfiguration", label: "Facial disfiguration" },
        { value: "Gang/Identify with group", label: "Gang/Identify with group" },
        { value: "Hair colour", label: "Hair colour" },
        { value: "Homophobic", label: "Homophobic" },
        { value: "Other reason", label: "Other reason" },
        { value: "Racist", label: "Racist" },
        { value: "Religious", label: "Religious" },
        { value: "Sexist", label: "Sexist" },
        { value: "Sexual", label: "Sexual" },
        { value: "Transactional (e.g. owe money)", label: "Transactional (e.g. owe money)" },
        { value: "Transgender", label: "Transgender" },
        { value: "Transphobic", label: "Transphobic" },
      ];
      // const optionsforcharacteristics = [
      //   { value: "Age", label: "Age" },
      //   { value: "Appearance", label: "Appearance" },
      //   { value: "Disability", label: "Disability" },
      //   { value: "Dislike of men", label: "Dislike of men" },
      //   { value: "Dislike of women", label: "Dislike of women" },
      //   { value: "Facial disfiguration", label: "Facial disfiguration" },
      //   { value: "Gender identity", label: "Gender identity" },
      //   { value: "Hair colour", label: "Hair colour" },
      //   { value: "Homophobia", label: "Homophobia" },
      //   { value: "Hygiene", label: "Hygiene" },
      //   { value: "Mental Health", label: "Mental Health" },
      //   { value: "Neurodiversity", label: "Neurodiversity" },
      //   { value: "Pregnancy", label: "Pregnancy" },
      //   { value: "Race / Racism", label: "Race / Racism" },
      //   { value: "Religion / Religious beliefs", label: "Religion / Religious beliefs" },
      //   // { value: "beliefs", label: "beliefs" },
      //   { value: "Sexism", label: "Sexism" },
      //   { value: "Sexual orientation", label: "Sexual orientation" },
      //   { value: "Transphobia", label: "Transphobia" },
      //   { value: "Other", label: "Other" },
      // ];

      return (
        <div>
          <div className="font-medium justify-center items-center flex text-gray-900 bg-black w-full">
            <img className="h-14 w-100% 3xl:h-24 m-auto" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col p-4 md:p-8  h-full">
          <div className="font-medium justify-end items-end flex text-gray-900">
              <div className="md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xsrounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <MenuAlt1Icon
                          className="w-6 h-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="Info">
                          {({ active }) => (
                            <button
                              onClick={infoFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Info
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Pause">
                          {({ active }) => (
                            <button
                              onClick={popupPauseCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Pause
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Escalate">
                          {({ active }) => (
                            <button
                              onClick={popupEscalateCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Escalate
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Home">
                          {({ active }) => (
                            <button
                              onClick={HomeFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Home
                            </button>
                          )}
                        </Menu.Item>
                        {/* ))} */}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                </div>
              </div>
              {pauseCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <PauseCase onSubmit={pauseCase} popupClose={popupPauseCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              {escalateCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-y-auto flex-1 min-h-screen fixed inset-0 flex items-center w-fit my-2 md:max-w-[70%] mx-auto z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <EscalateCase onSubmit={escalatedCase} popupClose={popupEscalateCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              <button className="ml-4 inline-block align-middle select-none font-normal text-sm whitespace-no-wrap rounded px-1 text-gray-800" onClick={popupSubFormClose}><XCircleIcon
                className="w-6 h-6"
                aria-hidden="true"
              /></button>
              {/* <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button> */}
            </div>
            {/* <div className="font-medium justify-end items-end flex text-gray-900">
              <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button>
            </div> */}
            <div className="justify-center mt-3 mb-4 md:mb-6">
              <p className="text-2xl md:text-3xl 3xl:text-4xl font-bold text-center mx-4 xl:mx-16 mainTitle">
                Were any of the following characteristics present in relation to
                the incident?
              </p>
              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
            </div>
            <div className="grid grid-cols-12 gap-5 md:gap-8 m-8">
              <div className="sm:col-span-12 col-span-full item-center inline-block">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  What type of conflict was experienced?
                </label>
                <div className="w-3/4 md:w-1/2 mx-auto">
                  <Container data={optionsConflict.length > 0 ? optionsConflict : []} onChange={onChange} />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-5 md:gap-8 m-8">
              <div className="sm:col-span-12 col-span-full item-center">
                <label className="block text-[1.1rem] font-semibold my-4 text-gray-600">
                  Were any of the following characteristics present in relation to
                  the incident?
                </label>
                <div className="w-3/4 md:w-1/2 mx-auto">
                  <MultiSelect
                    options={optionsforcharacteristics}
                    value={selectedOptioncharacteristics ? selectedOptioncharacteristics : []}
                    onChange={setSelectedOptioncharacteristics}
                    labelledBy="Select"
                    isCreatable={true}
                    hasSelectAll={false}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-5 md:gap-8 m-8">
              <div className="col-span-full inline-block ">
                <label className="block text-lg font-semibold my-4 text-gray-600">
                  Were drugs / alcohol involved?
                </label>
                <div className="mx-[25%]">
                  <div className="bg-white border border-black inline-flex mx-2 rounded-lg">
                    <input
                      type="radio"
                      name="drugs"
                      id="yes_quesPract"
                      value="Yes"
                      onChange={handleChangeSubForm}
                      checked={subFormData.drugs === `Yes`}
                      hidden
                    />
                    <label
                      htmlFor="yes_quesPract"
                      className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="bg-black text-white inline-flex mx-2 rounded-lg">
                    <input
                      type="radio"
                      name="drugs"
                      id="no_quesPract"
                      value="No"
                      onChange={handleChangeSubForm}
                      checked={subFormData.drugs === `No`}
                      hidden
                    />
                    <label
                      htmlFor="no_quesPract"
                      className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-full text-center mt-auto mx-auto flex items-center ">
              <button
                id="prevBtn"
                type="button"
                onClick={prevStep}
                className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
              >
                <span>PREVIOUS </span>
              </button>
              <button
                type="submit"
                onClick={Continue}
                id="nextBtn"
                className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white button focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span>NEXT </span>
              </button>

            </div>
          </div>
        </div>
      );

    case 5:
      const optionsforSolutions = [
        { value: "To be friends" },
        { value: "To make amends" },
        { value: "To not communicate with each other" },
        { value: "To say sorry" },
        { value: "Not get involved when other pupils are being hurtful" },
        { value: "To tell our carer if something happens" },
        { value: "To be polite to each other" },
        { value: "To listen to what they have to say" },
        { value: "To tell our parent if something happens" },
        { value: "To make sure everyone can be involved" },
        { value: "To let our friends know we have moved on" },
        { value: "To go to a teacher first" },
        { value: "To keep an open mind" },
        { value: "To stop saying upsetting things" },
        { value: "To contribute to the solution" },
        { value: "To make sure everyone feels safe" },
        { value: "To stay away from each other" },
        { value: "To ignore comments from other pupils" },
        { value: "To hear why they did it" },
        { value: "To be respectful" },
        { value: "Other ADD" },
      ];

      return (
        <div>
          <div className="font-medium justify-center items-center flex text-gray-900 bg-black w-full">
            <img className="h-14 w-100% 3xl:h-24 m-auto" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col p-4 md:p-8  h-full">
          <div className="font-medium justify-end items-end flex text-gray-900">
              <div className="md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xsrounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <MenuAlt1Icon
                          className="w-6 h-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="Info">
                          {({ active }) => (
                            <button
                              onClick={infoFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Info
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Pause">
                          {({ active }) => (
                            <button
                              onClick={popupPauseCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Pause
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Escalate">
                          {({ active }) => (
                            <button
                              onClick={popupEscalateCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Escalate
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Home">
                          {({ active }) => (
                            <button
                              onClick={HomeFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Home
                            </button>
                          )}
                        </Menu.Item>
                        {/* ))} */}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                </div>
              </div>
              {pauseCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <PauseCase onSubmit={pauseCase} popupClose={popupPauseCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              {escalateCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-y-auto flex-1 min-h-screen fixed inset-0 flex items-center w-fit my-2 md:max-w-[70%] mx-auto z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <EscalateCase onSubmit={escalatedCase} popupClose={popupEscalateCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              <button className="ml-4 inline-block align-middle select-none font-normal text-sm whitespace-no-wrap rounded px-1 text-gray-800" onClick={popupSubFormClose}><XCircleIcon
                className="w-6 h-6"
                aria-hidden="true"
              /></button>
              {/* <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button> */}
            </div>
            {/* <div className="font-medium justify-end items-end flex text-gray-900">
              <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button>
            </div> */}
            <div className="justify-center mt-3 mb-4 md:mb-6">
              <p className="text-2xl md:text-3xl 3xl:text-4xl font-bold text-center  mainTitle">
                What solutions are needed to move on
              </p>
              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
            </div>
            <form onSubmit={addTableRows}>
              <div className="grid grid-cols-12 gap-5 md:gap-8 m-8">
                <div className="col-span-12">
                  <div className="inline-grid grid-cols-12 gap-4 w-full">
                    <p className="sm:col-span-6 col-span-full text-lg font-semibold my-4 text-right text-gray-600">
                      {currentUserInfo().firstName ? currentUserInfo().firstName : ''}, what is it that you need now from
                    </p>
                    <select
                      onChange={handleChangeDataforTable}
                      // value={rowsDataTemp.name}
                      name='name'
                      id="AgreementSelectMenu"
                      className="sm:col-span-3 col-span-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    >
                      <option value=""></option>
                      {/* {console.log(users)} */}
                      {users.map((e, key) => {
                        { console.log(e.id) }
                        if (e.id != currentUserForSubForm) {
                          return (
                            <option className="text-[1rem]" id={e.id} value={e.id}>
                              {e.firstName + ' ' + e.lastName}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  <ul className="grid gap-4 w-full md:grid-cols-4 mt-6">
                    {optionsforSolutions.map((e, key) => {
                      return (
                        <li>
                          <input
                            type="checkbox"
                            id={'checkbox' + key}
                            name='solutionCheckbox'
                            className="hidden peer"
                            value={e.value}
                            onChange={handleChangeForCheckboxs}
                          />
                          <label
                            htmlFor={'checkbox' + key}
                            className="inline-flex justify-between items-center text-[1rem] p-3 w-full h-full text-gray-600 bg-white rounded-lg border-2 border-gray-200 cursor-pointer peer-checked:border-green-600 hover:text-gray-800 peer-checked:text-gray-800 hover:bg-gray-50"
                          >
                            <div className="block">
                              <div className="w-full text-[1rem] font-medium">
                                {e.value}
                              </div>
                            </div>
                          </label>
                        </li>
                      );
                    })}
                    <li>
                      <input
                        type="checkbox"
                        id="input1"
                        className="hidden peer"
                        name='solutionCheckbox'
                        value={otherCheckBox1}
                        onChange={handleChangeForCheckboxs}
                      />
                      <label
                        htmlFor="input1"
                        className="inline-flex justify-between items-center p-3 w-full h-full text-gray-600 bg-white rounded-lg border-2 border-gray-200 cursor-pointer peer-checked:border-green-600 hover:text-gray-800 peer-checked:text-gray-800 hover:bg-gray-50"
                      >
                        <div className="block w-full">
                          Other
                          <input
                            onChange={e => setOtherCheckBox1(e.target.value)}
                            type="text"
                            placeholder="enter here"
                            className="w-full border-2 border-gray-200 p-1"
                          ></input>
                        </div>
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="input2"
                        className="hidden peer"
                        name='solutionCheckbox'
                        value={otherCheckBox2}
                        onChange={handleChangeForCheckboxs}
                      />
                      <label
                        htmlFor="input2"
                        className="inline-flex justify-between items-center p-3 w-full h-full text-gray-600 bg-white rounded-lg border-2 border-gray-200 cursor-pointer peer-checked:border-green-600 hover:text-gray-800 peer-checked:text-gray-800 hover:bg-gray-50"
                      >
                        <div className="block w-full">
                          Other
                          <input
                            type="text"
                            placeholder="enter here"
                            className="w-full border-2 border-gray-200 p-1"
                            onChange={e => setOtherCheckBox2(e.target.value)}
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-span-full text-center m-4">
                {/* <p className="sm:col-span-6 col-span-full text-lg font-semibold my-4 text-center text-gray-600">
                Do you need anything else from anyone else in order to move on?
              </p> */}
                <button
                  type="submit"
                  className="inline-flex max-h-12 bottom-0 my-auto items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md bg-green-600 hover:bg-green-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span> Add </span>
                </button>
              </div>
              {/* {subFormData.solutions.map} */}
              {rowsData.length > 0 ? (
                <div className="m-8">
                  <div className=" -my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="align-middle md:px-6 lg:px-8">
                      <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                        <table className="min-w-full table-auto divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 w-1/4 text-left font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 w-1/2 text-left font-semibold text-gray-900 sm:pl-6"
                              >
                                Solutions
                              </th>
                              <th className="w-1/4">
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {rowsData.map((e, key) => {
                              return (<tr key={e.name}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                  <div className="flex items-center">
                                    <div className="ml-2">
                                      <div className="font-medium text-sm text-gray-900">
                                        {users.map((user, key) => {
                                          if (user.id == e.name) {
                                            return (
                                              <>{user.firstName + ' ' + user.lastName}</>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
                                  <div className="flex items-center">
                                    <div className="ml-2">
                                      <div className="font-medium text-gray-900">
                                        {/* {e.solutions.forEach(function(element){string += element;})} */}
                                        {e.solutions.join(',')}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                  <div className="flex items-center">
                                    <div className="ml-2">
                                      <div className="font-medium text-gray-900">
                                        <button className="inline-block align-middle select-none border font-normal text-xs whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={() => (deleteTableRows(key))}>x</button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>) : ''
              }
              <div className="col-span-full text-center mt-auto mb-4 mx-auto flex items-center w-fit">
                <button
                  id="prevBtn"
                  type="button"
                  onClick={prevStep}
                  className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
                >
                  <span>PREVIOUS </span>
                </button>
                <button
                  type="button"
                  onClick={Continue}
                  id="nextBtn"
                  className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white button focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span>NEXT </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    case 6:
      return (
        <div>
          <div className="font-medium justify-center items-center flex text-gray-900 bg-black w-full">
            <img className="h-14 w-100% 3xl:h-24 m-auto" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col p-4 md:p-8  h-full">
          <div className="font-medium justify-end items-end flex text-gray-900">
              <div className="md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xsrounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <MenuAlt1Icon
                          className="w-6 h-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="Info">
                          {({ active }) => (
                            <button
                              onClick={infoFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Info
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Pause">
                          {({ active }) => (
                            <button
                              onClick={popupPauseCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Pause
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Escalate">
                          {({ active }) => (
                            <button
                              onClick={popupEscalateCaseDiv}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Escalate
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="Home">
                          {({ active }) => (
                            <button
                              onClick={HomeFun}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm w-full text-gray-700"
                              )}
                            >
                              Home
                            </button>
                          )}
                        </Menu.Item>
                        {/* ))} */}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                </div>
              </div>
              {pauseCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <PauseCase onSubmit={pauseCase} popupClose={popupPauseCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              {escalateCaseDiv ? (
                <>
                  <div
                    id="overlay"
                    className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                  />
                  <div
                    id="dialogForPerson"
                    className="overflow-y-auto flex-1 min-h-screen fixed inset-0 flex items-center w-fit my-2 md:max-w-[70%] mx-auto z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                  >
                    <EscalateCase onSubmit={escalatedCase} popupClose={popupEscalateCaseDivClose} />
                  </div>
                </>
              ) : (
                ""
              )}
              <button className="ml-4 inline-block align-middle select-none font-normal text-sm whitespace-no-wrap rounded px-1 text-gray-800" onClick={popupSubFormClose}><XCircleIcon
                className="w-6 h-6"
                aria-hidden="true"
              /></button>
              {/* <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button> */}
            </div>
            {/* <div className="font-medium justify-end items-end flex text-gray-900">
              <button className="inline-block align-middle select-none border font-normal text-base 3xl:text-2xl whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={popupSubFormClose}>x</button>
            </div> */}
            <div className="justify-center mt-3 mb-4 md:mb-6">
              <p className="text-2xl md:text-3xl 3xl:text-4xl font-bold text-center  mainTitle">
                Proposed Solutions
              </p>
              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
            </div>
            <div className="justify-center m-2">

              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
              <div className="px-4 py-2 sm-16 md:p-8">
                <p className="text-lg md:text-xl font-bold text-center">
                  The Practitioner should repeat this process with all Participants.
                </p>
                <p className="text-lg md:text-xl font-bold text-center">
                  The first stage of the SJA resolution process is complete.
                </p>
              </div>
              <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
            </div>
            <div className="col-span-full text-center mt-auto mx-auto flex items-center w-fit">
              <button
                id="prevBtn"
                type="button"
                onClick={prevStep}
                className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
              >
                <span>PREVIOUS </span>
              </button>
              <button
                type="submit"
                onClick={SubmitSubForm}
                id="nextBtn"
                className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md text-white button focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span>SUBMIT </span>
              </button>

            </div>


          </div>
        </div>
      );
  }
};


export default UserDetailForm;
