import React, { useState } from "react"

export default function PauseCase(props) {

    const intialFormData = Object.freeze({ reason: '', particioantsEscalate: '', keenYoResolve: '' })
    const [pauseCaseData, updatePauseCaseData] = React.useState(intialFormData);


    const handleChangePauseForm = (e) => {
        updatePauseCaseData({
            ...pauseCaseData,
            [e.target.name]: e.target.value
        })
        console.log(pauseCaseData)

    };

    const popupPauseCaseClose = () => {
        props.popupClose();
    }

    const handleSubmitPauseCase = (e) => {
        e.preventDefault()
        props.onSubmit(pauseCaseData)
        console.log(pauseCaseData);
    };

    const optionsForPauseReason = [
        { value: "Refusal to engage" },
        { value: "Unavailable at present" },
        { value: "Unwell" },
        { value: "Not ready" },
        { value: "Anxious about the process" },
    ];

    return (
        <>
            <form onSubmit={handleSubmitPauseCase}>
                <div className="justify-center mt-3 mb-4 md:mb-7">
                    <p className="text-xl md:text-2xl xl:text-2xl 3xl:text-2xl font-bold text-center ">
                        Pause case
                    </p>
                    <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                </div>
                <div className="grid grid-cols-12 gap-3 md:gap-8 p-2 md:p-4">
                    <div className="md:col-span-6 col-span-full">
                        <label
                            htmlFor="description"
                            className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                        >
                            Reason for pausing the case <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="mt-2 relative rounded-md shadow-sm">
                            <select
                                onChange={handleChangePauseForm}
                                // value={rowsDataClassificationTemp.name}
                                name='reason'
                                className="sm:col-span-3 col-span-12 bg-gray-50 border border-gray-300 text-gray-900 text-[1rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                                <option value="" />
                                {optionsForPauseReason.map((e, key) => {
                                    return (
                                        <option key={key} value={e.value}>
                                            {e.value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-span-full">
                        <label
                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                        >
                            Is there an immediate risk that the conflict between the participants will escalate? <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="float-right">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="particioantsEscalate"
                                    id="yes_particioantsEscalate"
                                    value="Yes"
                                    onChange={handleChangePauseForm}
                                    hidden
                                />
                                <label
                                    htmlFor="yes_particioantsEscalate"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    Yes
                                </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="particioantsEscalate"
                                    id="no_particioantsEscalate"
                                    value="No"
                                    onChange={handleChangePauseForm}
                                    hidden
                                />
                                <label
                                    htmlFor="no_particioantsEscalate"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-full inline-block">
                        <label
                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                        >
                            Are the participants keen to resolve this issue? <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="float-right">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="keenYoResolve"
                                    id="yes_keenYoResolve"
                                    value="Yes"
                                    onChange={handleChangePauseForm}
                                    hidden
                                />
                                <label
                                    htmlFor="yes_keenYoResolve"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    Yes
                                </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="keenYoResolve"
                                    id="no_keenYoResolve"
                                    value="No"
                                    onChange={handleChangePauseForm}
                                    hidden
                                />
                                <label
                                    htmlFor="no_keenYoResolve"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        id="addPersonDetails"
                        type="submit"
                        className="px-5 py-2 m-2 bg-green-600 hover:bg-green-800 text-white cursor-pointer rounded-md"
                    >
                        Pause
                    </button>
                    <button
                        onClick={popupPauseCaseClose}
                        id="closePerson"
                        type="button"
                        className="px-5 py-2 m-2 bg-red-500 hover:bg-red-700 text-white cursor-pointer rounded-md"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </>
    )
};