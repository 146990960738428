import { createContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { allAllocatedIncidents } from "../api/user/user.service";
import Summary from "./reports/Summary";

export const allIncidentsData = createContext();

export default function Sidebar() {
  const [open, setOpen] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState(1)
  const location = useLocation();
  console.log(location.pathname);

  const allIncidents = () => {

    allAllocatedIncidents()
      .then((response) => {
        console.log(response);
        setIncidents(response.data);
        setIsLoading(false)
        console.log(incidents)
      });
  }
  const setDashboardValue = () => {
    if (location.pathname == '/dashboard/summary') setCounter(1)
    else if (location.pathname == '/dashboard/incidentsData') setCounter(2)
    else if (location.pathname == '/dashboard/intelligence') setCounter(3)
    else if (location.pathname == '/dashboard/trends') setCounter(4)
    else if (location.pathname == '/dashboard/management') setCounter(5)
  }

  useEffect(() => {
    console.log("get all users")
    setIsLoading(true)
    allIncidents();
    setDashboardValue()



  }, []);

  return (
    <allIncidentsData.Provider value={incidents}>

      <div className="flex">
        <div
          className={`w-[20%] flex-col h-auto min-h-[calc(100vh_-_4rem)] p-3 bg-[#3593c6] shadow duration-300`}
        >
          <div className="space-y-3">
            <div className="flex-1">

              <ul className="pt-2 pb-4 space-y-1 text-sm">
                <li>
                  <Link to='/dashboard/summary' onClick={() => { setCounter(1) }} className={counter == 1 ? "flex items-center p-2 text-base font-medium text-white border-l-4 border-[#f5a24f] " : 'flex items-center p-2 text-base font-normal text-white hover:text-lg'}>
                    {/* <svg aria-hidden="true" className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" /><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" /></svg> */}
                    <span className="ml-3">Summary</span>
                  </Link>
                </li>
                <li>
                  <Link to='/dashboard/incidentsData' onClick={() => { setCounter(2) }} className={counter == 2 ? "flex items-center p-2 text-base font-medium text-white border-l-4 border-[#f5a24f] " : 'flex items-center p-2 text-base font-normal text-white hover:text-lg'}>
                    <span className="ml-3">Incidents</span>
                  </Link>
                </li>
                <li>
                  <Link to='/dashboard/intelligence' onClick={() => { setCounter(3) }} className={counter == 3 ? "flex items-center p-2 text-base font-medium text-white border-l-4 border-[#f5a24f] " : 'flex items-center p-2 text-base font-normal text-white hover:text-lg'}>
                    <span className="ml-3">Intelligence</span>
                  </Link>
                </li>
                <li>
                  <Link to='/dashboard/trends' onClick={() => { setCounter(4) }} className={counter == 4 ? "flex items-center p-2 text-base font-medium text-white border-l-4 border-[#f5a24f] " : 'flex items-center p-2 text-base font-normal text-white hover:text-lg'}>
                    <span className="ml-3">Trends</span>
                  </Link>
                </li>
                <li>
                  <Link to='/dashboard/management' onClick={() => { setCounter(5) }} className={counter == 5 ? "flex items-center p-2 text-base font-medium text-white border-l-4 border-[#f5a24f] " : 'flex items-center p-2 text-base font-normal text-white hover:text-lg'}>
                    <span className="ml-3">Management</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <Summary /> */}
        <Outlet />
      </div>
    </allIncidentsData.Provider>
  );
}

